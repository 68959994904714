import { Area, KPISortByValue, PowerStationKPIData, SortDirection } from '../ilc-types';

export function sortPowerStationsByName(powerStations: PowerStationKPIData[]) {
  return [...powerStations].sort((a, b) => {
    const regex = /^([A-Za-z]+)(\d+)-(\d+)$/;
    const matchA = a.psName.match(regex);
    const matchB = b.psName.match(regex);

    if (!matchA || !matchB) {
      return 0;
    }

    const [, lettersA, prefixNumA, suffixNumA] = matchA;
    const [, lettersB, prefixNumB, suffixNumB] = matchB;

    const lettersComparison = lettersA.localeCompare(lettersB);
    if (lettersComparison !== 0) return lettersComparison;

    const prefixComparison = parseInt(prefixNumA, 10) - parseInt(prefixNumB, 10);
    if (prefixComparison !== 0) return prefixComparison;

    return parseInt(suffixNumA, 10) - parseInt(suffixNumB, 10);
  });
}

export function sortPowerStationsByKPIValue(
  kpiValue: KPISortByValue,
  powerStations: PowerStationKPIData[],
  sortDirection: SortDirection
) {
  return [...powerStations].sort((a, b) => {
    const comparison = a.powerKpis[kpiValue] - b.powerKpis[kpiValue];
    return sortDirection === 'asc' ? comparison : -comparison;
  });
}

export function sortAreas(arr: Area[]): Area[] {
  return [...arr].sort((a, b) => {
    const numA = parseInt(a.label.replace(/[^\d]/g, ''), 10);
    const numB = parseInt(b.label.replace(/[^\d]/g, ''), 10);
    return numA - numB;
  });
}