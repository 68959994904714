import { gql } from '@apollo/client';

const BESS_FINANCIAL_OUTPUT_DTO = `
  bessCapexTotalPrice
  bessCapexSpecificPrice
  lcos
  npv
  irr
  payback
  discountedPayback
  roi
  discountedPaybackCalculationResult
  paybackCalculationResult
  roiCalculationResult
  irrCalculationResult
  lcosCalculationResult
`;

export const ASK_FOR_SIMULATION_STATUS = gql`
  query AskForSimStatus($id: ID!) {
    askForSimStatus(id: $id) {
      stage
    }
  }
`;

export const LOAD_INCREMENTAL_RESULTS = gql`
  query LoadIncrementalResults($id: ID!) {
    loadIncrementalResults(id: $id) {
      __typename @skip(if: true)
      id
      name
      stage
      number
      results {
        __typename @skip(if: true)
        ratedPower
        peakPower
        ratioDcAc
        totalUnusedPeakPower
        maximumPeakPower
        yearZeroEnergyToGridAfterAvailability
        specificProduction
        performanceRatio
        totalPrice
        specificPrice
        lcoe
        npv
        irr
        roi
        payback
        discountedPayback
        lcoeCalculationResult
        irrCalculationResult
        roiCalculationResult
        paybackCalculationResult
        discountedPaybackCalculationResult
        activePowerAc
        energyCapacity
        operationHours
        activePowerDc
        energyCapacityDc
        operationHoursDc
        interconnectionOutputData {
          __typename @skip(if: true)
          ohlResults {
            __typename @skip(if: true)
            lineName
            lineCapacity
            lineLength
            numTowers
            voltageDrop
            losses
          }
          substationResults {
            __typename @skip(if: true)
            substationName
            substationArrangement
            numberTransformers
            transformerType
            biggestTransformerRatedPower
          }
          switchingBreakingStationResults {
            __typename @skip(if: true)
            switchingBreakingStationName
            breakingStationCapacity
            numberFeeders
            mvCableCrossSection
          }
          interconnectionResultsGeneral {
            activePowerPointOfInterconnection
            ratioDcAcPointOfInterconnection
          }
        }
        outputPlotAreaData {
          name
          ratedPower
          peakPower
          peakPowerMax
          peakPowerRemaining
          dcAcRatio
          __typename @skip(if: true)
        }
      }
      layoutResults {
        layoutGrid
        layoutFull
        layout3D
      }
      primaryTag
      secondaryTag
      layoutEditorTag
      storeStatus
      completed
      publishedDate
      expireDocsDate
      hideSimulationDate
      outdated
      errorMessage
      error
    }
  }
`;

export const SAVE_SIMULATIONS = gql`
  mutation SetSimulationsStoreSaved($ids: [ID!]) {
    setSimulationsStoreSaved(ids: $ids)
  }
`;
export const DELETE_SIMULATIONS = gql`
  mutation RemoveSimulations($ids: [ID!]) {
    removeSimulations(ids: $ids)
  }
`;

export const COMBINED_SIMULATION_RESULTS = gql`
  query CombinedSimulationResults($id: ID!, $userSimulations: Boolean, $pagination: PaginationForm!) {
    loadCombinedSimulationsResults(id: $id, userSimulations: $userSimulations, pagination: $pagination) {
      saBessResult: saBessContent {
        id
        name
        userName
        storeStatus
        type
        publishedDate
        category
        primaryTag
        secondaryTag
        notes
        error
        stage
        completed
        number
        __typename @skip(if: true)
        results {
          activePowerAc
          energyCapacity
          operationHours
          __typename @skip(if: true)
        }
      }
      pvResult: pvContent {
        id
        name
        userName
        storeStatus
        type
        publishedDate
        category
        primaryTag
        secondaryTag
        layoutEditorTag
        notes
        error
        stage
        completed
        number
        __typename @skip(if: true)
        results {
          ratedPower
          peakPower
          ratioDcAc
          __typename @skip(if: true)
        }
      }
      pageInfo {
        pageNumber
        totalPages
      }
    }
  }
`;

export const STANDALONE_BESS_INCREMENTAL_RESULTS = gql`
  query LoadStandAloneBessIncrementalResults($id: ID!) {
    loadStandAloneBessIncrementalResults(id: $id) {
      __typename @skip(if: true)
      id
      name
      stage
      number
      results {
        __typename @skip(if: true)
        activePowerAc
        energyCapacity
        operationHours
        dispatchOutputDataDTO {
          totalDischargedEnergy
          dischargedEnergyToGrid
          consumedEnergyFromGrid
          averageDailyPriceSpread
          averageDailyCycles
          batteryLifeSpan
        }
        financialOutputDTO {
          ${BESS_FINANCIAL_OUTPUT_DTO}
        }
      }
      layoutResults {
        layoutFull
      }
      storeStatus
      completed
      publishedDate
      expireDocsDate
      hideSimulationDate
      outdated
      errorMessage
      error
    }
  }
`;
