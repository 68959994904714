import React from 'react';
import MaintenanceWrapper from 'pages/maintenance/maintenance-wrapper/maintenance-wrapper';
import { useTranslation } from 'react-i18next';
import { IndexIds } from 'index.ids';

const MaintenancePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <MaintenanceWrapper
      title={t('maintenance:maintenance-in-progress')}
      subtitle={t('maintenance:currently-in-maintenance')}
      dataTestId={IndexIds.Maintenance.MaintenancePage}
    />
  );
};

export default MaintenancePage;
