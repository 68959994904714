import { PrimaryActionTypes } from '../ilc-store';
import { Edge } from '../ilc-types';

export function getNodeIsEnabledByMode(mode: PrimaryActionTypes): boolean {
  const enableActionTypes = [
    PrimaryActionTypes.ADD_NEW_VERTEX_TO_EXISTING_VERTEX,
    PrimaryActionTypes.CONNECT_TWO_EXISTING_VERTICES,
    PrimaryActionTypes.ADD_NODE_TO_CLOSEST,
  ];
  return enableActionTypes.includes(mode);
}

export function getRoadIsDisabled(edge: Edge): boolean {
  const disableMovementTypes: Edge['type'][] = ['ACCESS_ROAD', 'ACCESS_BRIDGE', 'CUSTOM_BRIDGE', 'BRIDGE_OUTSIDE_AA'];
  return disableMovementTypes.includes(edge.type);
}
