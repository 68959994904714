import { Point } from '../ilc-types';
import { CameraControls } from '@react-three/drei';
import * as THREE from 'three';

export function flyToArea(points: Point[], controls: typeof CameraControls | undefined) {
  if (!controls) return;
  const minReferenceBox = {
    width: 100,
    height: 200,
  };
  const { xPoints, yPoints } = points.reduce(
    (acc: { xPoints: number[]; yPoints: number[] }, point) => {
      acc.xPoints.push(point.x);
      acc.yPoints.push(point.y);
      return acc;
    },
    { xPoints: [], yPoints: [] }
  );
  const minX = Math.min(...xPoints) - minReferenceBox.width / 2;
  const minY = Math.min(...yPoints) - minReferenceBox.height / 2;
  const maxX = Math.max(...xPoints) + minReferenceBox.width / 2;
  const maxY = Math.max(...yPoints) + minReferenceBox.height / 2;
  const minVector = new THREE.Vector3(minX, 0, -minY);
  const maxVector = new THREE.Vector3(maxX, 0, -maxY);
  const bbox = new THREE.Box3(minVector, maxVector);
  controls.fitToBox(bbox, true);
}

export const centerAroundBbox = (newBbox: { min: Point; max: Point }, controls: typeof CameraControls | undefined) => {
  if (controls) {
    const currentAzimuthAngle = controls.azimuthAngle;
    const minVector = new THREE.Vector3(newBbox.min.x, 0, -newBbox.min.y);
    const maxVector = new THREE.Vector3(newBbox.max.x, 0, -newBbox.max.y);
    const box3 = new THREE.Box3(minVector, maxVector);
    controls.setTarget(0, 3, 0, false);
    controls.setPosition(0, 3, 0, false);
    controls.fitToBox(box3, false);
    controls.azimuthAngle = currentAzimuthAngle;
    controls.update();
  }
};