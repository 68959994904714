import React, { useEffect } from 'react';
import { RatedPowerLoader } from '@ratedpower/components';
import { useAuth0 } from '@auth0/auth0-react';
import { LOGIN_REQUIRED, MFA_REQUIRED } from 'utils/constants/users';
import { clearLocalStorage } from 'services/local-storage';

const Login: React.FC<{ apiError: boolean }> = ({ apiError }) => {
  const { loginWithRedirect, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const handleLogin = async () => {
      try {
        // Attempt silent authentication
        await getAccessTokenSilently();
      } catch (err) {
        // If silent authentication fails, handle the error
        if (err.message === LOGIN_REQUIRED || err.message === MFA_REQUIRED || apiError) {
          clearLocalStorage();
          // The user needs to enter credentials
          await loginWithRedirect({ authorizationParams: { prompt: 'login', display: 'popup' } });
        } else {
          console.error('Error during silent authentication:', err);
        }
      }
    };

    handleLogin();
  }, [getAccessTokenSilently, loginWithRedirect, apiError]);

  return <RatedPowerLoader />;
};

export default Login;
