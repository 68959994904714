import React from 'react';

import { SecondaryActionTypes, useModes, useMoveMultipleElements, useResetModeByStep } from '../ilc-store';

import { getMidPointsOfRectangleShortSide } from '../ilc-utils/geometry';
import { calculateIntersectionBetweenProjectedPoints } from '../utils/snap-to-line';
import { distance } from '../utils/maths';
import { Point, SelectedElement, SelectedElementWithSlopeData } from '../ilc-types';
import { ClickablePoints } from './clickable-points';

interface IReferenceForUniformAlignment {
  selectedElements: SelectedElement[];
  elementsGroups: Record<number, SelectedElementWithSlopeData[]>;
  groupsKeys: string[];
}

export const ReferenceForUniformAlignment = ({
  selectedElements,
  elementsGroups,
  groupsKeys,
}: IReferenceForUniformAlignment) => {
  const mode = useModes();
  const alignUniformlyMode = mode.secondaryMode.type === SecondaryActionTypes.ALIGN_UNIFORMLY;
  const resetModeByStep = useResetModeByStep();
  const onMoveMultipleElements = useMoveMultipleElements();

  const handleClick = (coords: Point) => {
    const elementsToUpdate: SelectedElement[] = [];

    for (let i = 0; i < groupsKeys.length; i++) {
      const group = elementsGroups[groupsKeys[i]];
      const groupsPoints = group.map((element) => element.rectangle).flat();
      const intersectionPoints: any = [];
      for (let j = 0; j < groupsPoints.length; j++) {
        const point = groupsPoints[j];
        const intersection = calculateIntersectionBetweenProjectedPoints(
          point,
          selectedElements[0].angle,
          coords,
          selectedElements[0].angle - Math.PI / 2
        );

        if (intersection) {
          const distanceA = distance(intersection, point);
          intersectionPoints.push({
            point: intersection,
            distance: distanceA,
            offSet: { x: intersection.x - point.x, y: intersection.y - point.y },
          });
        }
      }
      intersectionPoints.sort((a: any, b: any) => a.distance - b.distance);
      if (intersectionPoints.length > 0 && intersectionPoints[0]) {
        const offSet = intersectionPoints[0].offSet;
        group.forEach((element) => {
          elementsToUpdate.push({
            ...element,
            centroid: { x: element.centroid.x + offSet.x, y: element.centroid.y + offSet.y },
          });
        });
      }
    }
    onMoveMultipleElements(elementsToUpdate);
    resetModeByStep();
  };

  if (!alignUniformlyMode) return null;

  const rectangleMidpoints = selectedElements.map((element) =>
    getMidPointsOfRectangleShortSide(element.centroid, element.length, element.angle)
  );

  return (
    <>
      {rectangleMidpoints.map((midpoint, index) => {
        return <ClickablePoints color="blue" onClick={handleClick} coords={midpoint} radius={0.8} key={index} />;
      })}
    </>
  );
};
