import styled from 'styled-components';
import { BulletPointContainer } from '@ratedpower/components';

export const UsersSummaryContainer = styled.div`
  min-width: 600px;
`;

export const RoleDescription = styled.div`
  align-items: baseline;
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;

  ${BulletPointContainer} {
    padding-right: 5px;
  }
`;
