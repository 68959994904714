import React from 'react';
import * as S from './comparisson-bar.styled';
import { IconsWrapper } from './comparisson-bar.styled';
import { useTranslation } from 'react-i18next';
import { ArrowDownSvg, ArrowUpSvg, FolderImportSvg, TrashSvg } from 'assets/icons';
import { useTheme } from 'styled-components';
import { IBessDesign, IDesign, SimulationPrimaryCharacteristicsTag } from 'types/design';
import { Button, Checkbox, ISort, StatusSVGWrapper, Toggle, Tooltip, Typography } from '@ratedpower/components';
import { COMPARISON_DESIGNS_LIMIT } from 'utils/constants/design-comparison';
import { useCanEditDesigns } from 'utils/hooks/use-can-edit-designs';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IProps {
  selectedDesigns: (IDesign | IBessDesign)[];
  sortBy: ISort;
  viewDetailed: boolean;
  handleArchive: () => void;
  handleCompare: () => void;
  handleDelete: () => void;
  onClickViewDetailed: () => void;
  onSort: () => void;
  toggleAll: () => void;
}

const ComparissonBar: React.FC<IProps> = ({
  selectedDesigns,
  sortBy,
  viewDetailed,
  handleArchive,
  handleCompare,
  handleDelete,
  onClickViewDetailed,
  onSort,
  toggleAll,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { canEditDesign } = useCanEditDesigns();
  const flags = useFlags();

  const numSelectedDesigns = selectedDesigns.length;

  function isAnyDesignWithErrorSelected() {
    return [...selectedDesigns].some((e) => e.status === 'ERROR');
  }

  const numOfSelectedDesignsIsOverTheLimit = numSelectedDesigns > COMPARISON_DESIGNS_LIMIT;

  const selectionIncludesBessDesigns = (selectedDesigns: (IDesign | IBessDesign)[]): boolean => {
    return selectedDesigns.map((design) => design.primaryTag).includes(SimulationPrimaryCharacteristicsTag.BESS);
  };

  const compareBtnIsDisabled =
    numSelectedDesigns < 2 ||
    isAnyDesignWithErrorSelected() ||
    numOfSelectedDesignsIsOverTheLimit ||
    selectionIncludesBessDesigns(selectedDesigns);

  const canEditAllDesigns = selectedDesigns.every((design: IDesign | IBessDesign) =>
    canEditDesign(design.createdBy.id, design.projectTeamId)
  );

  const iconsAreDisabled = !numSelectedDesigns || !canEditAllDesigns;
  const iconsTooltip = !numSelectedDesigns
    ? t('design:select-1-or-more-designs-to-delete-archive')
    : !canEditAllDesigns
    ? t('design:insufficient-permissions-to-execute-action')
    : undefined;

  const disableActionsForStandaloneBessInactive =
    selectionIncludesBessDesigns(selectedDesigns) && !flags?.skuStandaloneBess;

  const disableIcons = iconsAreDisabled || disableActionsForStandaloneBessInactive;

  const comparissonIcons = [
    {
      Icon: TrashSvg,
      value: 'trash',
      disabled: disableIcons,
      tooltip: iconsTooltip,
      onClick: handleDelete,
    },
    {
      Icon: FolderImportSvg,
      value: 'archive',
      disabled: disableIcons,
      tooltip: iconsTooltip,
      onClick: handleArchive,
    },
  ];
  const selectedDesignsText =
    numSelectedDesigns > 0
      ? t('design:n-designs-selected', { count: numSelectedDesigns })
      : t('design:n-designs-selected_zero');
  const thereAreDesignsSelected = numSelectedDesigns > 0;

  const comparisonTooltipText = selectionIncludesBessDesigns(selectedDesigns)
    ? t('design:bess-comparison-disabled')
    : selectedDesignsText;

  return (
    <S.Container>
      <S.LeftContainer>
        <Checkbox id="comparisson-bar" checked={thereAreDesignsSelected} onChange={() => toggleAll()} />
        {thereAreDesignsSelected && (
          <S.SelectedDesignsWrapper>
            <Typography>{selectedDesignsText}</Typography>
          </S.SelectedDesignsWrapper>
        )}
        <S.VerticalSeparator />
        {thereAreDesignsSelected && (
          <>
            <Tooltip place="bottom" description={comparisonTooltipText}>
              <Button
                disabled={compareBtnIsDisabled}
                variant="accent"
                text={t('action:compare')}
                onClick={() => handleCompare()}
              />
            </Tooltip>
            <S.VerticalSeparator />
            <IconsWrapper>
              {comparissonIcons.map((icon) => (
                <StatusSVGWrapper
                  active
                  size="m"
                  icon={icon.Icon}
                  onClick={icon.onClick}
                  tooltip={{
                    text: icon.tooltip ?? '',
                    place: 'bottom',
                  }}
                  key={icon.value}
                  disabled={icon.disabled}
                  color={theme.v2.icon.accent.default}
                />
              ))}
            </IconsWrapper>
            <S.VerticalSeparator />
          </>
        )}

        <Toggle
          isOn={viewDetailed}
          label={t('design:designs-details')}
          name="designDetails"
          onToggle={() => onClickViewDetailed()}
        />
      </S.LeftContainer>
      <Button
        variant="ghost"
        text={t('date')}
        Icon={sortBy.ascendent ? ArrowUpSvg : ArrowDownSvg}
        onClick={onSort}
        iconPosition="right"
      />
    </S.Container>
  );
};

export default ComparissonBar;
