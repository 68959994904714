import React, { useState } from 'react';
import * as S from './color-picker-styled';
import images from 'assets/images';
import { Portal } from '@ratedpower/components';
import { useOutsideClick } from 'utils/mouse';
import { useDebouncedCallback } from 'use-debounce';
import { HexColorPicker } from 'react-colorful';

interface IProps {
  color: string;
  colorWheelWidth: number;
  hexPanelWidth?: number;
  editable?: boolean;
  onChangeColor: (newColor: string) => void;
}

const ColorPicker: React.FC<IProps> = ({ color, colorWheelWidth, hexPanelWidth = 216, editable, onChangeColor }) => {
  const [isPaletteDisplayed, showPalette] = useState(false);
  const componentRef: React.RefObject<any> = React.createRef();

  useOutsideClick(componentRef, () => showPalette(false));

  const onChangeColorDebounced = useDebouncedCallback((newColor) => {
    onChangeColor(newColor);
  }, 300);

  return (
    <S.Container ref={componentRef} id="container" $colorWidth={colorWheelWidth} $hexPanelWidth={hexPanelWidth}>
      {color ? (
        <S.SelectedColor
          className="selected-color"
          onClick={() => editable && showPalette(!isPaletteDisplayed)}
          width={String(colorWheelWidth) + 'px'}
          color={color}
        />
      ) : (
        <S.HexImg
          src={images.ColorWheelSvg}
          width={colorWheelWidth}
          onClick={() => editable && showPalette(!isPaletteDisplayed)}
          alt="color-picker"
        />
      )}
      <Portal target="container">
        {isPaletteDisplayed && <HexColorPicker color={color} onChange={onChangeColorDebounced} />}
      </Portal>
    </S.Container>
  );
};

export default ColorPicker;
