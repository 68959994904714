import React from 'react';
import { DesignItemLoadingContainer } from '../loading/design-item-loading.styled';
import { DesignColumn } from '../../../design-column.styled';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { DESIGN_ACTIONS as action } from 'utils/constants/designs';
import { IBessDesign, IDesign, SIMULATION_TYPE } from 'types/design';
import { getDynamicDate } from 'utils/date';
import { Tag, Tooltip, Typography, ConditionalWrapper, StatusSVGWrapper } from '@ratedpower/components';
import { useCanCloneDesigns } from 'utils/hooks/use-can-clone-designs';
import { CloneSvg } from 'assets/icons';

interface IProps {
  item: IDesign | IBessDesign;
  onPerform: (action: string) => void;
}

const DesignItemLoading: React.FC<IProps> = ({ item, onPerform }) => {
  const {
    createdBy: { lastname, name },
    name: title,
    publishedDate,
  } = item;
  const { t } = useTranslation();

  const handleClone = (e: any) => {
    e.stopPropagation();
    onPerform(action.clone);
  };

  const canCloneDesigns = useCanCloneDesigns();

  const isSingle = item.simulationType === SIMULATION_TYPE.SINGLE;

  return (
    <DesignItemLoadingContainer>
      <div className="main-row">
        <DesignColumn className="first-column">
          <ConditionalWrapper
            condition={title.length > 20}
            wrapper={(children) => <Tooltip description={title}>{children}</Tooltip>}
          >
            <Typography size="m" weight="bold" nowrap>
              {item.name}
            </Typography>
          </ConditionalWrapper>
          <Typography size="s">{`${name} ${lastname}`}</Typography>
        </DesignColumn>
        <DesignColumn className="skeleton">
          <Skeleton count={1} />
        </DesignColumn>
        <DesignColumn className="data tags">
          <Tag color={isSingle ? 'green' : 'purple'} maxWidth="fit-content">
            {isSingle ? t('design-process:batch.single-design') : t('design-process:batch.multiple-pv-designs')}
          </Tag>
        </DesignColumn>

        <DesignColumn className="skeleton">
          <Skeleton count={1} />
        </DesignColumn>
        <DesignColumn className="skeleton">
          <Skeleton count={1} />
        </DesignColumn>
        <DesignColumn className="skeleton">
          <Skeleton count={1} />
        </DesignColumn>
        <DesignColumn className="skeleton">
          <Skeleton count={1} />
        </DesignColumn>
        <DesignColumn className="createdAt">
          <Typography>
            {t('design:design-date', {
              dateFormat: getDynamicDate(publishedDate),
            })}
          </Typography>
        </DesignColumn>
        <DesignColumn className="more collapsed">
          <Tooltip description={t('action:clone')}>
            <StatusSVGWrapper active size="m" icon={CloneSvg} onClick={handleClone} disabled={!canCloneDesigns} />
          </Tooltip>
        </DesignColumn>
      </div>
    </DesignItemLoadingContainer>
  );
};

export default DesignItemLoading;
