import React, { Fragment, useState, useMemo } from 'react';
import * as S from './power-stations-kpis-display.styled';
import { useTranslation } from 'react-i18next';
import { PowerStationKPIData } from '../../../ilc-types';
import { ChevronDownSvg, ChevronUpSvg, TargetSvg, DotSvg, FilterSvg } from 'assets/icons';
import { StatusSVGWrapper, Typography, SVGWrapper, Button } from '@ratedpower/components';
import { Collapse } from 'react-collapse';
import { KpisData } from '../kpis-data';
import {
  useGetRectangleElement,
  useKpisPowerStationsFilterState,
  useUpdateKpisPowerStationsFilterState,
} from '../../../ilc-store';
import { CameraControls } from '@react-three/drei';
import { track } from 'rudderstack/utils';
import { RudderstackEvent } from 'rudderstack/types';
import { flyToArea } from '../../../ilc-utils/camera';
import { sortPowerStationsByKPIValue, sortPowerStationsByName } from '../../../ilc-utils/sorting';

interface IPowerStationsKpisDisplay {
  powerStationsKpis: PowerStationKPIData[];
  controls: typeof CameraControls | undefined;
}

export function PowerStationsKpisDisplay({ powerStationsKpis, controls }: IPowerStationsKpisDisplay) {
  const { t } = useTranslation('ilc');
  const [expanded, setExpanded] = useState(false);
  const [expandedPowerStations, setExpandedPowerStations] = useState<string[]>([]);
  const getRectangleElement = useGetRectangleElement();
  const updateKpisPowerStationsFilterState = useUpdateKpisPowerStationsFilterState();
  const { sortBy, sortDirection } = useKpisPowerStationsFilterState();

  const powerStationsKpisToDisplay = useMemo(() => {
    switch (sortBy) {
      case 'name': {
        return sortPowerStationsByName(powerStationsKpis);
      }
      case 'peakDcPower': {
        return sortPowerStationsByKPIValue('peakDcPower', powerStationsKpis, sortDirection);
      }
      case 'ratedPower': {
        return sortPowerStationsByKPIValue('ratedPower', powerStationsKpis, sortDirection);
      }
      case 'dcAcRatio': {
        return sortPowerStationsByKPIValue('dcAcRatio', powerStationsKpis, sortDirection);
      }
    }
  }, [sortBy, powerStationsKpis, sortDirection]);

  const handleToggleExpandedPowerStation = (psKey: string) => {
    if (expandedPowerStations.includes(psKey)) {
      setExpandedPowerStations(expandedPowerStations.filter((ps) => ps !== psKey));
      return;
    }
    setExpandedPowerStations([...expandedPowerStations, psKey]);
  };

  const handleClickPowerStationTarget = (psKey: string) => {
    const rectangleElement = getRectangleElement(psKey);
    if (!rectangleElement) return;
    flyToArea([rectangleElement.centroid], controls);
  };

  return (
    <S.PowerStationKpisDisplayWrapper>
      <div>
        <S.TitleHeader>
          <StatusSVGWrapper
            icon={expanded ? ChevronUpSvg : ChevronDownSvg}
            onClick={() => {
              track(RudderstackEvent.PV_EDITED_RESULTS_CLICK);
              setExpanded(!expanded);
            }}
            size="s"
            active={false}
          />
          <Typography weight="bold">{t('results.list-of-power-stations')}</Typography>
          <Button size="m" variant="ghost" onClick={() => updateKpisPowerStationsFilterState(true)} Icon={FilterSvg} />
        </S.TitleHeader>
        <Collapse isOpened={expanded}>
          {powerStationsKpisToDisplay.map((psData) => {
            const rectangleElement = getRectangleElement(psData.psKey);
            return (
              <Fragment key={psData.psKey}>
                <S.PowerStationItem>
                  <StatusSVGWrapper
                    icon={expandedPowerStations.includes(psData.psKey) ? ChevronUpSvg : ChevronDownSvg}
                    onClick={() => handleToggleExpandedPowerStation(psData.psKey)}
                    size="s"
                    active={false}
                  />
                  <SVGWrapper size="s" icon={DotSvg} color={rectangleElement?.color} />
                  <Typography>{psData.psName}</Typography>
                  <StatusSVGWrapper
                    icon={TargetSvg}
                    onClick={() => handleClickPowerStationTarget(psData.psKey)}
                    size="s"
                    active={false}
                  />
                </S.PowerStationItem>
                <Collapse isOpened={expandedPowerStations.includes(psData.psKey)}>
                  <S.CollapseContent>
                    <KpisData powerKpi={psData.powerKpis} />
                  </S.CollapseContent>
                </Collapse>
              </Fragment>
            );
          })}
        </Collapse>
      </div>
    </S.PowerStationKpisDisplayWrapper>
  );
}
