import { gql } from '@apollo/client';

const BASE_PARAM = `
  name
  unit
  description
`;

const fragments = {
  entry: gql`
    fragment Entry on CapexTemplateEntryDTO {
      id
      name
      visible
      publishedDate
      status
      children {
        id
        name
        visible
        publishedDate
        status
        children {
          id
          name
          visible
          publishedDate
          status
          priceDefinition {
            id
            name
            baseParam {
              ${BASE_PARAM}
            }
            paramMultiplier
            tax
            price
          }
          children {
            id
            name
            visible
            publishedDate
            status
            priceDefinition {
              id
              name
              baseParam {
                ${BASE_PARAM}
              }
              paramMultiplier
              tax
              price
            }
          }
        }
      }
    }
  `,
  templates: gql`
    fragment SimpleTemplate on CapexTemplateSimpleDTO {
      id
      name
      country {
        translation
        countryCode
      }
      currency
      notes
      createdBy {
        name
        surname
      }
      company {
        id
      }
      status
      publishedDate
      folder {
        label
        name
      }
      countryDefault
      modifiedDate
    }
  `,
  template: gql`
    fragment Template on CapexTemplateDTO {
      id
      name
      country {
        translation
        countryCode
      }
      folder {
        name
        label
      }
      currency
      notes
      createdBy {
        name
        surname
      }
      company {
        id
      }
      status
      publishedDate
      modifiedDate
      countryDefault
      originalTemplateId
    }
  `,
};

export const GET_BASE_PARAMS = gql`
  query CapexBaseParams {
    capexBaseParams {
      name
      unit
      description
    }
  }
`;

export const GET_BESS_BASE_PARAMS = gql`
  query CapexBessBaseParams {
    capexBessBaseParams {
      name
      unit
      description
    }
  }
`;

export const GET_DEFAULT_TEMPLATE = gql`
  query DefaultTemplate($countryCode: String, $simulationId: String) {
    result: defaultTemplate(countryCode: $countryCode, simulationId: $simulationId) {
      entries {
        ...Entry
      }
      ...Template
    }
  }
  ${fragments.template}
  ${fragments.entry}
`;

export const GET_BESS_DEFAULT_TEMPLATE = gql`
  query DefaultBessTemplate($simulationId: String) {
    result: defaultBessTemplate(simulationId: $simulationId) {
      entries {
        ...Entry
      }
      ...Template
    }
  }
  ${fragments.template}
  ${fragments.entry}
`;

export const DEFAULT_TEMPLATE_EXISTS = gql`
  query CheckIfDefaultTemplateExists($countryCode: String) {
    result: checkIfDefaultTemplateExists(countryCode: $countryCode) {
      id
      name
      folder {
        label
        name
      }
      country {
        translation
      }
    }
  }
`;

export const GET_CAPEX_TEMPLATES = gql`
  query CapexTemplates(
    $pagination: PaginationForm!
    $simulationId: String
    $companyId: String
    $sorting: [SortOrder]
    $filters: [CapexTemplateFilterEntry]
    $isIncludedRatedPower: Boolean!
    $isPublicTemplates: Boolean!
    $search: [String]
  ) {
    result: capexTemplates(
      pagination: $pagination
      simulationId: $simulationId
      companyId: $companyId
      sorting: $sorting
      filters: $filters
      isIncludedRatedPower: $isIncludedRatedPower
      isPublicTemplates: $isPublicTemplates
      search: $search
    ) {
      content {
        ...SimpleTemplate
      }
      pageInfo {
        pageNumber
        totalPages
      }
    }
  }
  ${fragments.templates}
`;

export const GET_CAPEX_TEMPLATE = gql`
  query CapexTemplate($id: ID!) {
    capexTemplate(id: $id) {
      entries {
        ...Entry
      }
      ...Template
    }
  }
  ${fragments.template}
  ${fragments.entry}
`;

export const GET_TRANSLATED_COUNTRIES = gql`
  query GetTranslatedCountries($userLanguage: UserLanguage!, $search: [String]) {
    translatedCountriesList(userLanguage: $userLanguage, search: $search) {
      id
      translation
      countryCode
    }
  }
`;

export const GET_CAPEX_ENTRIES = gql`
  query CapexTemplate($id: ID!) {
    capexTemplate(id: $id) {
      id
      entries {
        ...Entry
      }
    }
  }
  ${fragments.entry}
`;

export const GET_AVAILABLE_CURRENCY = gql`
  query AvailableCurrencies {
    availableCurrencies {
      currency
      symbol
    }
  }
`;

export const GET_CAPEX_FOLDERS = gql`
  query CapexFolders {
    capexFolders {
      name
      label
    }
  }
`;

export const GET_CAPEX_CREATORS = gql`
  query CompanyCapexCreators($companyId: ID, $pagination: PaginationForm!, $sorting: [SortOrder], $search: String) {
    result: companyCapexCreators(companyId: $companyId, pagination: $pagination, sorting: $sorting, search: $search) {
      content {
        name
        id
      }
      pageInfo {
        pageNumber
        totalPages
      }
    }
  }
`;

export const SAVE_CAPEX_TEMPLATE = gql`
  mutation CreateCapexTemplate($form: CapexTemplateCreateDTO!) {
    createCapexTemplate(form: $form) {
      entries {
        ...Entry
      }
      ...Template
    }
  }
  ${fragments.template}
  ${fragments.entry}
`;

export const UPDATE_CAPEX_TEMPLATE = gql`
  mutation UpdateCapexTemplate($form: CapexTemplateUpdateDTO!) {
    updateCapexTemplate(form: $form) {
      entries {
        ...Entry
      }
      ...Template
    }
  }
  ${fragments.template}
  ${fragments.entry}
`;

export const DELETE_CAPEX_TEMPLATE = gql`
  mutation DeleteCapexTemplate($id: ID!) {
    deleteCapexTemplate(id: $id) {
      name
      id
    }
  }
`;
