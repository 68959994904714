import React from 'react';
import DesignGridItemErrorCard from './error-card/design-grid-item-error-card';
import DesignGridItemLoading from './loading/design-grid-item-loading';
import DesignItemDetails from '../../list/item/design-item-details';
import { DESIGN_ACTIONS as action } from 'utils/constants/designs';
import { CardHeaderWrapper, DesignGridItemContainer } from './design-grid-item.styled';
import { IBessDesign, IDesign, SimulationSecondaryCharacteristicsTag } from 'types/design';
import { Link } from 'react-router-dom';
import { RemoteImage } from 'utils/hooks/use-remote-image';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useCanEditDesigns } from 'utils/hooks/use-can-edit-designs';
import { useCanCloneDesigns } from 'utils/hooks/use-can-clone-designs';
import { GenericStatus } from 'utils/constants/status';
import DesignGridItemNote from 'pages/designs/grid/item/design-grid-item-note/design-grid-item-note';
import { buildRelativeDesignProjectUrl } from 'utils/navigation/navigation';
import { isPvDesign } from 'utils/design-type-functions';
import BessDesignItemDetails from 'pages/designs/list/item/bess-design-item-details';
import { DesignGridItemHeaderDetails } from './header/design-grid-item-header-details';
import DesignGridItemHeader from './header/design-grid-item-header';
import { BessDesignGridItemHeaderDetails } from 'pages/standalone-bess/designs/bess-design-grid-item-header-details';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DesignTag } from 'components/design-components/design-tag/design-tag';
import {
  getPrimaryTagText,
  getSecondaryTagText,
  selectedPrimaryTagColor,
  selectedSecondaryTagColor,
} from 'utils/design-tags';
import { useStandaloneBessSku } from 'utils/hooks/sku/use-standalone-bess-sku';
import { useHybridBessSku } from 'utils/hooks/sku/use-hybrid-bess-sku';
import { useIlcSku } from 'utils/hooks/sku/use-ilc-sku';
import { track } from 'rudderstack/utils';
import { RudderstackEvent } from 'rudderstack/types';

interface IProps {
  item: IDesign | IBessDesign;
  showDetails?: boolean;
  selected: boolean;
  onPerform: (action: string, item: IDesign | IBessDesign) => void;
  onEditName: (newName: string) => void;
}

const DesignGridItem: React.FC<IProps> = ({ item, showDetails, selected, onPerform, onEditName }) => {
  const { t } = useTranslation();
  const { canEditDesign } = useCanEditDesigns();
  const canCloneDesigns: boolean = useCanCloneDesigns();
  const canEditThisDesign: boolean = canEditDesign(item.createdBy.id, item.projectTeamId);
  const designProcessUrl = buildRelativeDesignProjectUrl(item.id, item.primaryTag);
  const flags = useFlags();
  const { restrictStandaloneBessSku } = useStandaloneBessSku();
  const { restrictHybridBessSku } = useHybridBessSku();
  const { restrictIlcSku } = useIlcSku();

  const handleStopPropagation = (e: any): void => {
    e.stopPropagation();
  };

  const handleSelect = (): void => {
    onPerform(action.select, item);
  };

  const handleFavorite = (): void => {
    onPerform(action.favorite, item);
  };

  const handleClone = (e: any): void => {
    e.preventDefault();
    handleStopPropagation(e);
    onPerform(action.clone, item);
  };

  const handleUnarchive = (): void => {
    onPerform(action.unarchive, item);
  };

  const handleArchive = (): void => {
    onPerform(action.archive, item);
  };

  const handleDelete = (): void => {
    onPerform(action.delete, item);
  };

  const handleEditNotes = (e: React.MouseEvent<Element, MouseEvent>): void => {
    e.stopPropagation();
    e.preventDefault();
    track(RudderstackEvent.DESIGN_NOTE_EDIT, { design_category: `${item.primaryTag}-${item.secondaryTag}` });
    onPerform(action.editNotes, item);
  };

  const handleViewNotes = (e: React.MouseEvent<Element, MouseEvent>): void => {
    e.stopPropagation();
    e.preventDefault();
    onPerform(action.viewNotes, item);
  };

  if (item.simulationResult === GenericStatus.IN_PROGRESS || item.simulationResult === GenericStatus.QUEUED) {
    return <DesignGridItemLoading showDetails={!!showDetails} />;
  }

  if (item.simulationResult !== GenericStatus.IN_PROGRESS && item.simulationResult !== 'OK') {
    return (
      <DesignGridItemErrorCard
        canCloneDesign={canCloneDesigns}
        canEditDesign={canEditThisDesign}
        item={item}
        selected={selected}
        onPerform={onPerform}
        handleSelect={handleSelect}
        handleFavorite={handleFavorite}
        handleClone={handleClone}
        handleDelete={handleDelete}
        handleEditName={onEditName}
        handleEditNotes={handleEditNotes}
        handleViewNotes={handleViewNotes}
        showDetails={showDetails}
      />
    );
  }

  const isDisabledColor =
    restrictIlcSku(item) || restrictStandaloneBessSku(item) || restrictHybridBessSku(item) || !canCloneDesigns;

  return (
    <Link to={designProcessUrl}>
      <DesignGridItemContainer $isDefault className={classNames({ selected })}>
        <div className="card-image">
          <DesignTag color={selectedPrimaryTagColor(item, isDisabledColor)} position={{ top: 8, right: 8 }}>
            {t(getPrimaryTagText(item))}
          </DesignTag>
          {item.secondaryTag === SimulationSecondaryCharacteristicsTag.EDITED && (
            <DesignTag
              color={selectedSecondaryTagColor(item, !flags?.skuIlc || !canCloneDesigns)}
              position={{ top: 38, right: 8 }}
            >
              {t(getSecondaryTagText(item))}
            </DesignTag>
          )}

          <RemoteImage width={326} height={200} url={item.satellitePicture.thumbnail} alt="Layout view" />
        </div>
        <CardHeaderWrapper>
          <DesignGridItemHeader
            canCloneDesign={canCloneDesigns}
            canEditDesign={canEditThisDesign}
            item={item}
            selected={selected}
            handleSelect={handleSelect}
            handleClone={handleClone}
            handleDelete={handleDelete}
            handleUnarchive={handleUnarchive}
            handleArchive={handleArchive}
            handleEditName={onEditName}
            handleFavorite={handleFavorite}
          />
          {isPvDesign(item) ? (
            <DesignGridItemHeaderDetails item={item} />
          ) : (
            <BessDesignGridItemHeaderDetails item={item} />
          )}
        </CardHeaderWrapper>
        <div
          className={classNames('card-detail-row', {
            hidden: !showDetails,
          })}
        >
          {showDetails &&
            (isPvDesign(item) ? <DesignItemDetails {...{ item }} /> : <BessDesignItemDetails {...{ item }} />)}
        </div>
        <DesignGridItemNote
          item={item}
          canEditNotes={
            canEditThisDesign &&
            !restrictStandaloneBessSku(item) &&
            !restrictHybridBessSku(item) &&
            !restrictIlcSku(item)
          }
          handleEditNotes={handleEditNotes}
          handleViewNotes={handleViewNotes}
        />
      </DesignGridItemContainer>
    </Link>
  );
};

export default DesignGridItem;
