import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, IModalProps, InputTextArea, LabelWrapper } from '@ratedpower/components';
import useForm from 'utils/hooks/use-form';
import { IProjectForm } from 'types/projects';
import { useMutation } from '@apollo/client';
import { UPDATE_DESIGNS } from 'services/designs-gql';
import { IBessDesign, IDesign } from 'types/design';
import { track } from 'rudderstack/utils';
import { RudderstackEvent } from 'rudderstack/types';

interface IProps extends IModalProps {
  item: IDesign | IBessDesign;
  onSubmit: (newDesign: IProjectForm) => void;
}

const ModalEditNotes: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const handleSave = () => {
    const { notes } = inputs;
    const designForm = { notes, id: props.item.id };
    track(RudderstackEvent.DESIGN_NOTE_SAVE, {
      design_category: `${props.item.primaryTag}-${props.item.secondaryTag}`,
    });

    updateDesigns({
      variables: { designForms: [designForm] },
      onCompleted: () => {
        props.onSubmit({ ...props.item, ...{ notes } });
      },
    });
  };

  const { inputs, handleInputChange } = useForm(handleSave, props.item);
  const [updateDesigns] = useMutation(UPDATE_DESIGNS);

  return (
    <Modal
      {...props}
      disableClickOutside
      buttons={{
        primary: {
          text: t('action:save'),
          onClick: handleSave,
        },
        secondary: {
          text: t('action:discard'),
          onClick: props.onClose,
        },
      }}
    >
      <div data-testid="modal-edit-notes">
        <LabelWrapper htmlFor="notes" title={t('notes')} size="m">
          <InputTextArea
            dataTest="input-project-notes"
            value={inputs.notes || ''}
            onChange={handleInputChange}
            name="notes"
            maxLength={500}
            size="m"
            maxRows={Number.MAX_SAFE_INTEGER}
            minRows={3}
            helperText="&nbsp;"
          />
        </LabelWrapper>
      </div>
    </Modal>
  );
};

export default ModalEditNotes;
