import React from 'react';
import * as S from './delete-company.styled';
import { CompanyFormSection } from '../company-info-tab.styled';
import { Button, Typography } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import { FormSectionHeadline } from '../company-info-tab.styled';
import { useMutation } from '@apollo/client';
import { DELETE_COMPANY } from 'services/support-gql';
import { useToasts } from 'utils/hooks/use-toasts';
import { useModal } from 'utils/hooks/use-modal';
import { useNavigate } from 'react-router-dom';
import DeleteConfirmationModal from 'components/modals/delete-confirmation-modal/delete-confirmation-modal';

interface IProps {
  companyId: string;
  companyName: string;
}

const DeleteCompany: React.FC<IProps> = ({ companyId, companyName }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const deleteModal = useModal();
  const { addSuccessToast, addErrorToast } = useToasts();

  const [deleteCompany] = useMutation(DELETE_COMPANY, {
    onCompleted: () => {
      addSuccessToast(t('company:delete-company-success'));
      navigate('/companies-activity');
    },
    onError: () => {
      addErrorToast(t('company:delete-company-error'));
    },
  });

  return (
    <CompanyFormSection>
      <FormSectionHeadline>
        <Typography size="l" weight="bold">
          {t('company:delete-company')}
        </Typography>
      </FormSectionHeadline>
      <S.DeleteCompanyButton>
        <Button onClick={() => deleteModal.setModalOpen(true)} text={t('company:delete-company')} variant="error" />
      </S.DeleteCompanyButton>
      <DeleteConfirmationModal
        title="company:delete-company"
        description="company:delete-company-description"
        confirmationValue={companyName}
        confirmationValueLabel="company:enter-company-name"
        onSubmit={() => deleteCompany({ variables: { id: companyId } })}
        modal={deleteModal}
      />
    </CompanyFormSection>
  );
};

export { DeleteCompany };
