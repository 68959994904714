import React, { useEffect, useMemo, useState } from 'react';
import { ClockSvg } from 'assets/icons';
import * as S from './session-expiration-modal.styled';
import { useTranslation } from 'react-i18next';
import { Typography, IModalProps } from '@ratedpower/components';

interface IProps extends IModalProps {
  extendSession: () => void;
  endUserSession: () => void;
  onClose: () => void;
}

const SessionExpirationModal: React.FC<IProps> = ({ extendSession, endUserSession, onClose, ...rest }) => {
  const { t } = useTranslation();

  const [counter, setCounter] = useState(30);

  const formattedCounter = useMemo(() => {
    const minutes = Math.trunc(counter / 60);
    const seconds = Math.trunc(counter - minutes * 60);

    return `0${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }, [counter]);

  useEffect(() => {
    const setTimeoutRemaining = setTimeout(() => setCounter((currentCounter: number) => currentCounter - 1), 1000);
    if (counter < 0) onClose();

    return () => {
      clearTimeout(setTimeoutRemaining);
    };
  }, [counter]);

  return (
    <S.SessionExpirationModalContainer
      {...rest}
      disableClickOutside
      hideCloseIcon
      title={<ClockSvg />}
      buttons={{
        primary: {
          text: t('auth:extend-session'),
          onClick: extendSession,
        },
        secondary: {
          text: t('auth:logout'),
          onClick: endUserSession,
        },
      }}
      onClose={onClose}
    >
      <Typography size="l">{t('auth:your-session-is-about-to-expired')}</Typography>
      <S.CountDown>{formattedCounter}</S.CountDown>
    </S.SessionExpirationModalContainer>
  );
};

export default SessionExpirationModal;
