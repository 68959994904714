import React from 'react';

import useForm from 'utils/hooks/use-form';
import { useTranslation } from 'react-i18next';
import IntegrationCheckbox from './integration-checkbox';
import { Secret } from 'types/company.types';
import { Button, InputText, LabelWrapper, Typography } from '@ratedpower/components';
import IntegrationCardHeader from './integration-card-header';
import { IntegrationFormWrapper, IntegrationApiNeededWrapper, ButtonsWrapper } from '../integrations.styled';
import { MeteoSources, meteoDataSourceLinks } from 'utils/constants/meteoDataSources';
import { useTheme } from 'styled-components';
import { NewTabSvg } from 'assets/icons';

interface IProps {
  data: { apiKey: string };
  onSaveIntegration: (form: { apiKey: string }) => void;
  onDiscard: () => void;
  integrationSecret: Secret;
  checkAutoGenerate: boolean;
  isEditing: boolean;
  onEdit: () => void;
  toggleCheckAutogenerate: () => void;
}

const MeteoIntegrationsForm: React.FC<IProps> = ({
  data,
  onSaveIntegration,
  isEditing,
  onEdit,
  integrationSecret,
  checkAutoGenerate,
  toggleCheckAutogenerate,
  onDiscard,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { inputs, handleInputChangeManual, formError, clearValue } = useForm(handleSaveIntegration, data);

  function handleSaveIntegration() {
    const { apiKey } = inputs;
    onSaveIntegration({ apiKey });
  }

  function handleOnFocus() {
    clearValue('apiKey');
    if (checkAutoGenerate) {
      toggleCheckAutogenerate();
    }
  }

  const saveIsDisabled = integrationSecret.status === 'DISABLED' && !inputs.apiKey;

  const needsAcquireInfo = !isEditing && !data.apiKey;

  return (
    <>
      <IntegrationCardHeader integrationSecret={integrationSecret} isEditing={isEditing} onEdit={onEdit} />
      <IntegrationFormWrapper>
        {needsAcquireInfo ? (
          <IntegrationApiNeededWrapper>
            <Typography color={theme.gray_dark}>
              {t('company:need-api-key-meteo-integration', { integrationName: integrationSecret.publicName })}
            </Typography>
            <Button
              Icon={NewTabSvg}
              text={t('company:get-it-here')}
              iconPosition="right"
              onClick={() => {
                window.open(meteoDataSourceLinks[integrationSecret.secret as MeteoSources], '_blank');
              }}
            />
          </IntegrationApiNeededWrapper>
        ) : (
          <>
            <LabelWrapper title={t('company:api-key')}>
              <InputText
                warning={!!formError && formError.code}
                name="apiKey"
                value={inputs.apiKey || ''}
                onFocus={handleOnFocus}
                onChange={(value) => handleInputChangeManual('apiKey', value)}
                readOnly={!isEditing}
                disabled={!isEditing}
                size="m"
              />
            </LabelWrapper>
            <IntegrationCheckbox
              id={integrationSecret.secret}
              publicName={integrationSecret.publicName}
              checkAutoGenerate={checkAutoGenerate}
              disabled={!inputs.apiKey || !isEditing}
              toggleCheckAutogenerate={toggleCheckAutogenerate}
            />
          </>
        )}
      </IntegrationFormWrapper>

      {isEditing && (
        <ButtonsWrapper>
          <Button text={t('action:cancel')} variant="ghost" onClick={onDiscard} fullWidth />
          {!saveIsDisabled && (
            <Button onClick={handleSaveIntegration} text={t('action:save')} variant="accent" fullWidth />
          )}
        </ButtonsWrapper>
      )}
    </>
  );
};

export default MeteoIntegrationsForm;
