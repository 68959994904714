import React from 'react';
import * as S from 'pages/maintenance/maintenance-wrapper/maintenance-wrapper.styled';
import { MaintenanceSvg } from 'assets/icons/index';
import { Button, Typography } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import { openExternalLink } from 'utils/navigation';

const STATUS_URL = ' https://status.ratedpower.com/';

interface IProps {
  title: string;
  subtitle: string;
  dataTestId: string;
}

const MaintenanceWrapper: React.FC<IProps> = ({ title, subtitle, dataTestId }: IProps) => {
  const { t } = useTranslation();

  const openStatusPage = () => {
    openExternalLink(STATUS_URL);
  };

  return (
    <S.MaintenanceWrapper data-testid={dataTestId}>
      <S.MaintenanceContainer>
        <MaintenanceSvg />
        <Typography weight="bold">{title}</Typography>
        <Typography size="s">{subtitle}</Typography>
        <Button variant="accent" text={t('maintenance:check-status')} onClick={openStatusPage} />
      </S.MaintenanceContainer>
    </S.MaintenanceWrapper>
  );
};

export default MaintenanceWrapper;
