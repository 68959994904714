import React, { useState } from 'react';
import * as THREE from 'three';
import { movePointAlongLineUsingAngleAndDistance } from '../../ilc-utils/geometry';
import { PrimaryActionTypes, useModes, useMoveElementsByOffset } from '../../ilc-store';
import * as S from './structure-movement-inputs.styled';
import { InputNumber, Typography } from '@ratedpower/components';
import { useTheme } from 'styled-components';

enum StructureMovementType {
  AXIAL_MOVEMENT = 'AXIAL_MOVEMENT',
  PITCH_MOVEMENT = 'PITCH_MOVEMENT',
}

interface IStructureMovementInputsProps {
  disabled: boolean;
}

function StructureMovementInputs({ disabled }: Readonly<IStructureMovementInputsProps>) {
  const [axialValue, setAxialValue] = useState('0');
  const [pitchValue, setPitchValue] = useState('0');
  const onMoveElementsByOffset = useMoveElementsByOffset();
  const action = useModes().mode;
  const theme = useTheme();

  const getAngle = (actionPayload: any[], movementType: StructureMovementType): number => {
    switch (movementType) {
      case StructureMovementType.AXIAL_MOVEMENT:
        return actionPayload[0].angle;
      case StructureMovementType.PITCH_MOVEMENT:
        return THREE.MathUtils.degToRad(-90) + actionPayload[0].angle;
    }
  };

  const onUpdateMovement = (inputValue: any, movementType: StructureMovementType) => {
    if (action.type !== PrimaryActionTypes.SELECT_RECTANGLES) return;

    const angle = getAngle(action.payload, movementType);

    const position = movePointAlongLineUsingAngleAndDistance({ x: 0, y: 0 }, angle, inputValue);

    onMoveElementsByOffset(action.payload, { x: position.x, y: position.y });
  };

  const labelColor = disabled ? theme.v2.text.primary.disabled : undefined;

  const handleUpdateAxialMovement = () => {
    if (!axialValue) return;
    onUpdateMovement(parseFloat(axialValue), StructureMovementType.AXIAL_MOVEMENT);
    setAxialValue('0');
  };

  const handleUpdatePitchMovement = () => {
    if (!pitchValue) return;
    onUpdateMovement(parseFloat(pitchValue), StructureMovementType.PITCH_MOVEMENT);
    setPitchValue('0');
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, movementType: StructureMovementType) => {
    if (e.key === 'Enter') {
      switch (movementType) {
        case StructureMovementType.AXIAL_MOVEMENT:
          handleUpdateAxialMovement();
          break;
        case StructureMovementType.PITCH_MOVEMENT:
          handleUpdatePitchMovement();
          break;
      }
    }
  };

  return (
    <S.StructureMovementInputsWrapper>
      <Typography as="label" color={labelColor}>
        Axial:
      </Typography>
      <InputNumber
        suffix="m"
        onChange={(value) => setAxialValue(value)}
        onBlur={() => handleUpdateAxialMovement()}
        onKeyDown={(e) => handleKeyDown(e, StructureMovementType.AXIAL_MOVEMENT)}
        size="s"
        value={axialValue}
        disabled={disabled}
        textAlign="right"
      />
      <Typography as="label" color={labelColor}>
        Pitch:
      </Typography>
      <InputNumber
        suffix="m"
        onChange={(value) => setPitchValue(value)}
        onBlur={() => handleUpdatePitchMovement()}
        onKeyDown={(e) => handleKeyDown(e, StructureMovementType.PITCH_MOVEMENT)}
        size="s"
        value={pitchValue}
        disabled={disabled}
        textAlign="right"
      />
    </S.StructureMovementInputsWrapper>
  );
}

export { StructureMovementInputs };
