import React from 'react';
import * as UsersTab from './users-tab.styled';
import { createColumnHelper } from '@tanstack/table-core';
import { useTranslation } from 'react-i18next';
import { BulletPoint, Button, Dropdown, PopupWrapper, SortableHeader, Tag, Typography } from '@ratedpower/components';
import { useTheme } from 'styled-components';
import { ISupportUserListItem, IUsersOptions } from 'types/support.types';
import { USER_STATUS as UserStatusEnum } from 'types/users';
import { defaultSelector } from 'utils/constants/support';
import { getDateMonthDDYYYY } from 'utils/date';
import { useListTeams } from 'pages/teams/hooks/use-list-teams';
import { getOriginalRolesColors, ROLE_USER, USER_STATUS } from 'utils/constants/users';
import { IRole, ROLE_TYPE } from 'types/role';
import { ADMIN_EMAIL } from 'utils/constants/contact';
import { PencilSvg, NewPasswordSvg, TrashSvg, DotsSvg } from 'assets/icons';
import { IndexIds } from 'index.ids';

const columnHelper = createColumnHelper<ISupportUserListItem>();

export const useUsersColumns = (userOptions: IUsersOptions) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { teams } = useListTeams({ infiniteScroll: false, pageSize: 30 });
  const companyHasTeams = !!teams?.length;
  const originalRolesColors = getOriginalRolesColors(theme);

  const getOptions = (canDeleteUsers: boolean, canResendPassword: boolean, isFirstLogin: boolean) => {
    const response = [
      {
        labelIcon: PencilSvg,
        label: t('action:edit'),
        value: 'edit',
      },
    ];
    if (isFirstLogin && canResendPassword) {
      response.push({
        labelIcon: NewPasswordSvg,
        label: t('support:users-tab.resend-pass'),
        value: 'resend-pass',
      });
    }
    if (canDeleteUsers) {
      response.push({
        labelIcon: TrashSvg,
        label: t('support:users-tab.delete-user'),
        value: 'delete-user',
      });
    }

    return response;
  };

  return [
    columnHelper.accessor('name', {
      header: () => (
        <SortableHeader
          onSort={userOptions.onSort}
          selector={{
            label: t('support:users-tab.name'),
            code: 'name',
          }}
          sortedBy={userOptions ? userOptions.sortedBy : defaultSelector}
        />
      ),
      cell: (info) => <Typography>{info.getValue()}</Typography>,
    }),
    columnHelper.accessor('lastname', {
      header: () => (
        <SortableHeader
          onSort={userOptions.onSort}
          selector={{
            label: t('support:users-tab.last-name'),
            code: 'surname',
          }}
          sortedBy={userOptions ? userOptions.sortedBy : defaultSelector}
        />
      ),
      cell: (info) => <Typography>{info.getValue()}</Typography>,
    }),
    columnHelper.accessor('email', {
      header: () => (
        <SortableHeader
          onSort={userOptions.onSort}
          selector={{
            label: t('support:users-tab.email'),
            code: 'email',
          }}
          sortedBy={userOptions ? userOptions.sortedBy : defaultSelector}
        />
      ),
      cell: (info) => <Typography>{info.getValue()}</Typography>,
    }),
    columnHelper.accessor('publishedDate', {
      header: () => (
        <SortableHeader
          onSort={userOptions.onSort}
          selector={{
            label: t('support:date-created'),
            code: 'publishedDate',
          }}
          sortedBy={userOptions ? userOptions.sortedBy : defaultSelector}
        />
      ),
      cell: (info) => <Typography>{info.getValue() ? getDateMonthDDYYYY(info.getValue() as Date) : '-'}</Typography>,
    }),
    columnHelper.accessor('lastLogin', {
      header: () => (
        <SortableHeader
          onSort={userOptions.onSort}
          selector={{
            label: t('support:users-tab.last-login'),
            code: 'lastLogin',
          }}
          sortedBy={userOptions ? userOptions.sortedBy : defaultSelector}
        />
      ),
      cell: (info) => <Typography>{info.getValue() ? getDateMonthDDYYYY(info.getValue() as Date) : '-'}</Typography>,
    }),
    ...(companyHasTeams
      ? [
          columnHelper.accessor('id', {
            header: () => <Typography>{t('user:teams.teams-assigned')}</Typography>,
            cell: (info) => (
              <Button
                variant="ghost"
                onClick={(e) => {
                  e.stopPropagation();
                  userOptions.handleAction(info.getValue() as string, 'show-teams-assigned');
                }}
                text={t('user:teams.view-teams')}
              />
            ),
          }),
        ]
      : []),
    columnHelper.accessor('status', {
      header: () => (
        <SortableHeader
          onSort={userOptions.onSort}
          selector={{
            label: t('support:users-tab.status'),
            code: 'status',
          }}
          sortedBy={userOptions ? userOptions.sortedBy : defaultSelector}
        />
      ),
      cell: (info) => (
        <div
          onClick={() => {
            userOptions.handleAction(
              info.row.original.id,
              info.row.original.status === UserStatusEnum.DISABLED ? 'enable' : 'disable'
            );
          }}
        >
          <Tag color={info.getValue() === UserStatusEnum.DISABLED ? 'gray' : 'teal'}>
            {t(`user:status-${USER_STATUS[info.getValue()]}`)}
          </Tag>
        </div>
      ),
    }),
    columnHelper.accessor('role', {
      header: () => (
        <SortableHeader
          onSort={userOptions.onSort}
          selector={{
            label: t('support:users-tab.role'),
            code: 'role.name',
          }}
          sortedBy={userOptions ? userOptions.sortedBy : defaultSelector}
        />
      ),
      cell: (info) => {
        const role = info.getValue();
        if (!role) return <Typography>-</Typography>;
        return (
          <UsersTab.RoleContainer>
            {!userOptions.canChangePermissions && role?.type === ROLE_TYPE.INTERNAL ? (
              <BulletPoint color={info.getValue()?.color ?? String(originalRolesColors[role.name])}>
                {role.name || ROLE_USER}
              </BulletPoint>
            ) : (
              <Dropdown
                value={[
                  {
                    value: role.id,
                    label: role.name,
                  },
                ]}
                onChange={(option) => userOptions.onSelectRole(option[0].value, info.row.original.id)}
                options={userOptions.roles
                  .filter((role: IRole) => {
                    if (!userOptions.canChangePermissions || userOptions.companyEmail !== ADMIN_EMAIL) {
                      return role.type === ROLE_TYPE.EXTERNAL;
                    }
                    return userOptions.canChangePermissions;
                  })
                  .map(({ name, id }) => ({
                    label: name,
                    value: id,
                    description: t(`user:roles.descriptions.${name}`, { defaultValue: '-' }),
                  }))}
              />
            )}
          </UsersTab.RoleContainer>
        );
      },
    }),
    columnHelper.accessor('jobTitle', {
      header: () => <></>,
      cell: (info) => (
        <PopupWrapper
          popupPosition="leftCenter"
          onClickOption={(option) => userOptions.handleAction(info.row.original.id, option.value)}
          options={getOptions(userOptions.canDeleteUsers, userOptions.canResendPassword, !info.row.original.lastLogin)}
          dataTest={IndexIds.CompaniesActivity.Actions}
        >
          <Button Icon={DotsSvg} variant="ghost" />
        </PopupWrapper>
      ),
    }),
  ];
};
