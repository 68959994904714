import mixpanel from 'mixpanel-browser';
import rudderAnalytics from 'rudderstack/utils';

export const mixPanelInit = (mixpanelToken: string) => {
  mixpanel.init(mixpanelToken, {
    api_host: 'https://api-v2-import.ingestion:9000',
    secure_cookie: true,
    record_sessions_percent: 100,
    loaded: function (mixpanel) {
      rudderAnalytics.ready(function () {
        const rudderAnonymousId = rudderAnalytics.getAnonymousId();
        const userId = rudderAnalytics.getUserId();
        const distinctId = userId ?? rudderAnonymousId;

        if (distinctId) {
          // Call identify() with the Distinct ID
          mixpanel.identify(distinctId);
          // Optionally, register additional properties
          mixpanel.register({ $device_id: rudderAnonymousId });
        }

        // Patch rudderstack track method to include sessionReplayProperties of mixpanel
        const originalTrack = rudderAnalytics.track;
        rudderAnalytics.track = function (eventName, eventProperties?, options?, callback?) {
          const sessionReplayProperties = mixpanel.get_session_recording_properties();
          eventProperties = {
            ...eventProperties,
            ...sessionReplayProperties,
          };
          originalTrack(eventName, eventProperties, options, callback);
        };

        // Patch rudderstack page method to include sessionReplayProperties of mixpanel
        const originalPage = rudderAnalytics.page;
        rudderAnalytics.page = function (...args) {
          const sessionReplayProperties = mixpanel.get_session_recording_properties();
          const properties = {
            ...args['properties'],
            ...sessionReplayProperties,
          };
          originalPage(args[0], args[1], properties, args[3], args[4]);
        };
      });
    },
  });
};
