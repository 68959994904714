import * as S from './instructions-box.styled';
import React from 'react';
import { PrimaryActionTypes, SecondaryActionTypes, useModes } from '../../ilc-store';
import { useTranslation } from 'react-i18next';
import { SelectedElement } from '../../ilc-types';

export function InstructionsBox() {
  const action = useModes().mode;
  const secondaryAction = useModes().secondaryMode;
  const { t } = useTranslation('ilc');

  const showInstructionsBox =
    action.type !== PrimaryActionTypes.NONE || secondaryAction.type !== PrimaryActionTypes.NONE;

  const showPowerStationWarning =
    action.type === PrimaryActionTypes.SELECT_RECTANGLES &&
    action.payload.some((item) => item.type === 'POWER_STATIONS');

  type CategorizedSelection = {
    STRUCTURES?: SelectedElement[];
    POWER_STATIONS?: SelectedElement[];
  };

  const getSelectionText = (items: SelectedElement[]): string => {
    const categorized = items.reduce((acc: CategorizedSelection, each) => {
      acc[each.type] = [...(acc[each.type] || []), each];
      return acc;
    }, {});

    const itemTypes = Object.keys(categorized);

    if (itemTypes.length === 1) {
      const type = itemTypes[0];
      const item = t(`instructions.${type}`);
      return t('instructions.selection-state.one', { count: categorized[type].length, item });
    }

    return t('instructions.selection-state.many', {
      itemA: t('instructions.selection-state.other', {
        count: categorized?.['STRUCTURES']?.length,
        item: t('instructions.STRUCTURES'),
      }),
      itemB: t('instructions.selection-state.one', {
        count: categorized?.['POWER_STATIONS']?.length,
        item: t('instructions.POWER_STATIONS'),
      }),
    });
  };

  const getInstructions = (): { title: string | null; content: string | null } => {
    if (action.type === PrimaryActionTypes.SELECT_REFERENCE_FOR_ROADS) {
      if (!action.payload) {
        return {
          title: t('instructions.align-to-reference-roads-select-title'),
          content: t('instructions.align-to-reference-roads-select-content'),
        };
      }
      return {
        title: t('instructions.align-to-reference-roads-move-title'),
        content: t('instructions.align-to-reference-roads-move-content'),
      };
    }

    if (action.type === PrimaryActionTypes.ADD_STRUCTURES) {
      if (action.payload.selectedStructureOption && !action.payload.selectedPs) {
        return {
          title: t('instructions.add-structures-define-mv-group-title'),
          content: t('instructions.add-structures-define-mv-group-content'),
        };
      }
      if (action.payload.selectedStructureOption && action.payload.selectedPs && !action.payload.referenceStructureId) {
        return {
          title: t('instructions.add-structures-select-reference-structure-title'),
          content: t('instructions.add-structures-select-reference-structure-content'),
        };
      }
      if (action.payload.selectedStructureOption && action.payload.selectedPs && action.payload.referenceStructureId) {
        return {
          title: t('instructions.add-structures-select-reference-point-title'),
          content: t('instructions.add-structures-select-reference-point-content'),
        };
      }
    }

    if (secondaryAction.type === SecondaryActionTypes.SELECT_PS_FOR_STRUCTURES) {
      return {
        title: t('instructions.edit-mv-group-title'),
        content: t('instructions.edit-mv-group-content'),
      };
    }

    if (secondaryAction.type === SecondaryActionTypes.ALIGN_UNIFORMLY) {
      return {
        title: t('instructions.align-uniformly-title'),
        content: t('instructions.align-uniformly-content'),
      };
    }

    if (secondaryAction.type === SecondaryActionTypes.ALIGN_SELECTED_STRUCTURES_WITH_SETBACKS) {
      if (secondaryAction.payload === 'snap-to-row') {
        return {
          title: t('instructions.align-setbacks-snap-to-row-title'),
          content: t('instructions.align-setbacks-snap-to-row-content'),
        };
      }
      if (secondaryAction.payload === 'snap-in-group') {
        return {
          title: t('instructions.align-setbacks-snap-in-group-title'),
          content: t('instructions.align-setbacks-snap-in-group-content'),
        };
      }
    }

    if (secondaryAction.type === SecondaryActionTypes.SELECT_REFERENCE) {
      if (!secondaryAction.payload.element) {
        return {
          title: t('instructions.align-to-reference-select-title'),
          content: t('instructions.align-to-reference-select-content'),
        };
      }
      if (!secondaryAction.payload.referencePoint) {
        return {
          title: t('instructions.align-to-reference-match-title'),
          content: t('instructions.align-to-reference-match-content'),
        };
      }
      return {
        title: t('instructions.align-to-reference-execute-title'),
        content: t('instructions.align-to-reference-execute-content'),
      };
    }

    if (action.type === PrimaryActionTypes.CONNECT_TWO_EXISTING_VERTICES) {
      if (!action.payload?.initialNodeId) {
        return {
          title: t('instructions.roads-connect-vertices-start-title'),
          content: t('instructions.roads-connect-vertices-start-content'),
        };
      }
      return {
        title: t('instructions.roads-connect-vertices-end-title'),
        content: t('instructions.roads-connect-vertices-end-content'),
      };
    }

    if (action.type === PrimaryActionTypes.ADD_NODE_TO_CLOSEST) {
      return {
        title: t('instructions.roads-add-node-to-closest-title'),
        content: t('instructions.roads-add-node-to-closest-content'),
      };
    }

    if (action.type === PrimaryActionTypes.ADD_NEW_VERTEX_TO_EXISTING_VERTEX) {
      if (!action.payload?.connectedNode) {
        return {
          title: t('instructions.roads-connect-to-existing-start-title'),
          content: t('instructions.roads-connect-to-existing-start-content'),
        };
      }
      return {
        title: t('instructions.roads-connect-to-existing-end-title'),
        content: t('instructions.roads-connect-to-existing-end-content'),
      };
    }

    if (action.type === PrimaryActionTypes.DELETE_NODE) {
      return {
        title: t('instructions.delete-node-and-keep-connection-title'),
        content: t('instructions.delete-node-and-keep-connection-content'),
      };
    }

    if (action.type === PrimaryActionTypes.DELETE_NODE_AND_CONNECTING_EDGES) {
      return {
        title: t('instructions.delete-node-remove-connection-title'),
        content: t('instructions.delete-node-remove-connection-content'),
      };
    }

    if (action.type === PrimaryActionTypes.SELECT_RECTANGLES) {
      return {
        title: null,
        content: getSelectionText(action.payload),
      };
    }

    return { title: null, content: null };
  };

  if (!showInstructionsBox) return null;

  const instructions = getInstructions();

  return (
    <S.Wrapper>
      <S.Title>{instructions.title}</S.Title>
      <S.Content>{instructions.content}</S.Content>
      {showPowerStationWarning && <S.Content>{t('instructions.power-station-warning')}</S.Content>}
    </S.Wrapper>
  );
}
