import React from 'react';
import * as S from './design-grid-item-notes.styled';
import { useTranslation } from 'react-i18next';
import { StatusSVGWrapper, Typography } from '@ratedpower/components';
import { EyeShowSvg, PencilSvg } from 'assets/icons/index';
import { IBessDesign, IDesign } from 'types/design';
import { useTheme } from 'styled-components';
import { getDynamicDate } from 'utils/date';

interface IProps {
  item: IDesign | IBessDesign;
  canEditNotes: boolean;
  handleEditNotes: (e: React.MouseEvent<Element, MouseEvent>) => void;
  handleViewNotes: (e: React.MouseEvent<Element, MouseEvent>) => void;
}

const DesignGridItemNote: React.FC<IProps> = ({ item, canEditNotes, handleEditNotes, handleViewNotes }) => {
  const { t } = useTranslation();
  const notesToPresent = item.notes || '-';
  const theme = useTheme();

  const statusSVGProps = canEditNotes
    ? {
        'icon': PencilSvg,
        'onClick': handleEditNotes,
        'data-testid': 'edit-notes-btn',
        'disabled': false,
      }
    : {
        icon: EyeShowSvg,
        onClick: handleViewNotes,
      };
  return (
    <S.DesignGridItemNote>
      <Typography weight={'bold'}>{t('notes')}</Typography>
      <S.DesignGridItemNoteDescription>
        <Typography nowrap size="s">
          {notesToPresent}
        </Typography>
        <StatusSVGWrapper {...statusSVGProps} size="s" active={false} />
      </S.DesignGridItemNoteDescription>
      <S.DesignGridItemNoteCreatedBy>
        <Typography color={theme.v2.text.secondary.default} size="s">
          {item.createdBy.name} {item.createdBy.lastname}
        </Typography>
        <Typography color={theme.v2.text.secondary.default} size="s">
          {getDynamicDate(item.publishedDate)}
        </Typography>
      </S.DesignGridItemNoteCreatedBy>
    </S.DesignGridItemNote>
  );
};

export default DesignGridItemNote;
