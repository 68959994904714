import React, { useRef, useState } from 'react';
import {
  PrimaryActionTypes,
  useAvailableAreas,
  useDesignInputs,
  useModes,
  useMoveElementsByOffset,
  useOnSelectElement,
  useSetbacks,
  SecondaryActionTypes,
  useMoveMultipleElements,
  useMovementType,
  useSelectReferenceElementMode,
  useResetModeByStep,
} from '../ilc-store';
import { useGesture } from '@use-gesture/react';
import {
  calculateIntersectionBetweenProjectedPointAndLine,
  calculateIntersectionBetweenProjectedPoints,
  calculateSlopeAndYInterceptOfPointAndAngle,
} from '../utils/snap-to-line';
import ReferencePoints from './reference-points';
import FatLine from './fat-line';
import { distance } from 'pages/interactive-layout-creator/utils/maths';
import {
  AlignToSetbackOption,
  IntersectionPoint,
  Line,
  Point,
  SelectedElement,
  SelectedElementWithSlopeData,
} from '../ilc-types';
import {
  calculateDirectionVector,
  calculateSelectedReferenceRectangle,
  convexHull,
  createRectangleWithCenterWithAngle,
  getElementSize,
  getPointerFromEvent,
  movePointAlongLineUsingDirectionVector,
  polygonToIndividualLines,
  selectedElementToRectangleWithPosition,
} from '../ilc-utils/geometry';
import { useWorldCoords } from 'pages/interactive-layout-creator/hooks/use-world-coords';
import { ReferenceForUniformAlignment } from './reference-for-uniform-alignment';
import { elevation } from './config';
import { useThree } from '@react-three/fiber';

interface SelectedElementProps {
  selectedElements: SelectedElement[];
}

const SelectedElements = (props: SelectedElementProps) => {
  const { size } = useThree();
  const onMoveElementsByOffset = useMoveElementsByOffset();
  const onMoveMultipleElements = useMoveMultipleElements();
  const movementType = useMovementType();

  const setbacks = useSetbacks();
  const getWorldCoords = useWorldCoords();
  const setbackType = props.selectedElements.find((element) => element.type === 'STRUCTURES') ? 'STRUCTURE' : 'PS';

  const filteredSetbacks = setbacks
    ? Object.values(setbacks)
        .flat()
        .filter((setback) => setback.type.includes(setbackType))
    : null;

  const exteriorSetbacks = filteredSetbacks
    ? filteredSetbacks.map((setback) => polygonToIndividualLines(setback.exteriorOffset, setback.type)).flat()
    : [];

  const interiorSetbacks = filteredSetbacks
    ? filteredSetbacks
        .map((setback) =>
          setback.interiorOffsets
            .map((interiorSetbacks: Point[]) => polygonToIndividualLines(interiorSetbacks, setback.type))
            .flat()
        )
        .flat()
    : [];

  const linesForSetbacks = [...exteriorSetbacks, ...interiorSetbacks];

  const designInputs = useDesignInputs();
  const mode = useModes();
  const secondaryAction = useModes().secondaryMode;
  const selectedReferenceElement =
    mode.secondaryMode.type === SecondaryActionTypes.SELECT_REFERENCE ? mode.secondaryMode.payload.element : null;
  const selectedReferencPoint =
    mode.secondaryMode.type === SecondaryActionTypes.SELECT_REFERENCE
      ? mode.secondaryMode.payload.referencePoint
      : null;
  const showSelectedReferencePoints = mode.secondaryMode.type === SecondaryActionTypes.SELECT_REFERENCE;
  const onSelectElement = useOnSelectElement();
  const selectReferenceElementMode = useSelectReferenceElementMode();
  const resetActionsByStep = useResetModeByStep();

  const groupRef = useRef<any>(null);

  const selectedReferenceRectangle = calculateSelectedReferenceRectangle(
    selectedReferenceElement,
    designInputs,
    props.selectedElements
  );

  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  });

  const availableAreas = useAvailableAreas();

  const [offsetFromDragPosition, setOffsetFromDragPosition] = useState<any>(null);

  const dragTimeout = useRef<any>(null);
  const isDragging = useRef(false);
  const DELAY_BEFORE_DRAG_END_FLAG = 100;

  const removeDragTimeout = () => {
    if (dragTimeout.current) {
      clearTimeout(dragTimeout.current);
      dragTimeout.current = null;
    }
  };

  const bind: any = useGesture(
    {
      onClick: (event: any) => {
        if (selectedReferenceElement) return;
        const intersections = event?.event?.intersections[0]?.eventObject?.userData?.id;
        if (!isDragging.current && intersections) {
          onSelectElement(intersections);
        }
        document.body.style.cursor = 'default';
      },
      onDragStart: ({ down, ...rest }: any) => {
        rest.event.stopPropagation();
        if (selectedReferenceElement) return;
        removeDragTimeout();
        isDragging.current = true;
        document.body.style.cursor = down ? 'grabbing' : 'grab';
        const newPosition = getWorldCoords(getPointerFromEvent(rest.event, size));
        const offset = {
          x: newPosition.x - position.x,
          y: newPosition.y - position.y,
        };
        setOffsetFromDragPosition(offset);
      },
      onDrag: ({ down, ...rest }: any) => {
        if (!groupRef.current || selectedReferenceElement || !isDragging.current) return;
        document.body.style.cursor = down ? 'grabbing' : 'grab';

        const newPosition = getWorldCoords(getPointerFromEvent(rest.event, size));
        if (!offsetFromDragPosition) return;

        const clickPosition = {
          x: newPosition.x - offsetFromDragPosition.x,
          y: newPosition.y - offsetFromDragPosition.y,
        };

        if (movementType === 'FREE') {
          setPosition(clickPosition);
        } else {
          const directionVector = calculateDirectionVector(
            { x: position.x, y: position.y },
            props.selectedElements[0].angle
          );

          const movement = movePointAlongLineUsingDirectionVector(
            { x: position.x, y: position.y },
            directionVector,
            clickPosition
          );
          setPosition({ x: movement.x, y: movement.y });
        }
      },
      onDragEnd: () => {
        dragTimeout.current = setTimeout(() => {
          isDragging.current = false;
        }, DELAY_BEFORE_DRAG_END_FLAG);
        document.body.style.cursor = 'default';
        if (selectedReferenceElement) return;
        setOffsetFromDragPosition(null);
        if (!availableAreas) return;
        onMoveElementsByOffset(props.selectedElements, { x: position.x, y: position.y });
        setPosition({ x: 0, y: 0 });
      },
    },
    {
      drag: {
        filterTaps: true,
      },
    }
  );

  const selectedRectangles = designInputs
    ? props.selectedElements.map((element) => selectedElementToRectangleWithPosition(element, position, designInputs))
    : [];

  const allPoints = selectedRectangles.map((rectangle) => rectangle.points).flat();

  const convexHullPoints = convexHull(allPoints);

  const groupSlopesWithTheSameRoundedYIntercept = (
    elementsData: SelectedElementWithSlopeData[]
  ): Record<string, SelectedElementWithSlopeData[]> => {
    const grouped: any = {};
    elementsData.forEach((data) => {
      const existingGroup = Object.keys(grouped).find((key) => Math.abs(Number(key) - data.roundedYIntercept) <= 2);
      if (existingGroup) {
        grouped[existingGroup].push(data);
      } else {
        grouped[data.roundedYIntercept] = [data];
      }
    });

    return grouped;
  };

  const getElementsGroups = (): Record<
    SelectedElementWithSlopeData['roundedYIntercept'],
    SelectedElementWithSlopeData[]
  > => {
    const allSlopes = props.selectedElements.map((element) => ({
      ...calculateSlopeAndYInterceptOfPointAndAngle(element.centroid, props.selectedElements[0].angle),
      ...element,
      rectangle: createRectangleWithCenterWithAngle(
        { x: element.centroid.x + position.x, y: element.centroid.y + position.y },
        element.width,
        element.length,
        element.angle
      ),
    }));

    const optimimizedSlopes = allSlopes.map((slope) => ({
      ...slope,
      roundedYIntercept: Math.floor(slope.yIntercept),
    }));

    return groupSlopesWithTheSameRoundedYIntercept(optimimizedSlopes);
  };

  const elementsGroups = getElementsGroups();
  const groupsKeys = Object.keys(elementsGroups);

  const handleClickOnSetbackLine = (line: Line, selection: AlignToSetbackOption) => {
    const line2 = {
      point1: line.start,
      point2: line.end,
    };

    if (selection === 'snap-to-row') {
      const elementsToUpdate: SelectedElement[] = [];

      for (let i = 0; i < groupsKeys.length; i++) {
        const group: SelectedElementWithSlopeData[] = elementsGroups[groupsKeys[i]];
        const groupsPoints = group.map((element) => element.rectangle).flat();
        const intersectionPoints: IntersectionPoint[] = [];
        for (let j = 0; j < groupsPoints.length; j++) {
          const point = groupsPoints[j];
          const intersection = calculateIntersectionBetweenProjectedPointAndLine(
            point,
            props.selectedElements[0].angle,
            line2
          );

          if (intersection) {
            const distanceA = distance(intersection, point);
            intersectionPoints.push({
              point: intersection,
              distance: distanceA,
              offSet: { x: intersection.x - point.x, y: intersection.y - point.y },
            });
          }
        }
        intersectionPoints.sort((a: any, b: any) => a.distance - b.distance);
        if (intersectionPoints.length > 0 && intersectionPoints[0]) {
          const offset = intersectionPoints[0].offSet;
          group.forEach((element) => {
            elementsToUpdate.push({
              ...element,
              centroid: { x: element.centroid.x + offset.x, y: element.centroid.y + offset.y },
            });
          });
        }
      }
      onMoveMultipleElements(elementsToUpdate);
      resetActionsByStep();
      return;
    }
    if (selection === 'snap-in-group') {
      const intersectionPoints: any = [];
      for (let i = 0; i < convexHullPoints.length; i++) {
        const point = convexHullPoints[i];
        const intersection = calculateIntersectionBetweenProjectedPointAndLine(
          point,
          props.selectedElements[0].angle,
          line2
        );
        if (intersection) {
          const distanceA = distance(intersection, point);
          intersectionPoints.push({
            point: intersection,
            distance: distanceA,
            offSet: { x: intersection.x - point.x, y: intersection.y - point.y },
          });
        }
      }
      intersectionPoints.sort((a: any, b: any) => a.distance - b.distance);
      if (intersectionPoints.length > 0 && intersectionPoints[0]) {
        const offSet = intersectionPoints[0].offSet;
        onMoveElementsByOffset(props.selectedElements, { x: offSet.x, y: offSet.y });
        resetActionsByStep();
      }
    }
  };

  if (!designInputs) return <></>;

  return (
    <>
      <group position={[position.x, 0, -position.y]} {...bind()} ref={groupRef}>
        {props.selectedElements.map((element) => (
          <mesh
            key={element.id}
            renderOrder={6}
            {...props}
            visible
            position={[element.centroid.x, elevation.selectedElement, -element.centroid.y]}
            rotation={[-Math.PI / 2, 0, element.angle]}
            userData={{ id: element.id }}
            onPointerMissed={(event) => {
              event.stopPropagation();
            }}
          >
            <planeGeometry args={getElementSize(element, designInputs.areaParametricInputs[element.areaKey])} />
            <meshBasicMaterial color="black" />
          </mesh>
        ))}
      </group>

      {showSelectedReferencePoints &&
        selectedRectangles.map((rectangle) => (
          <ReferencePoints
            color="green"
            key={rectangle.id}
            onClick={(coords) => {
              if (selectedReferenceElement) {
                selectReferenceElementMode(selectedReferenceElement.id, coords);
              }
            }}
            center={rectangle.center}
            userCoords={rectangle.points}
            showReferencePoints={false}
            showMidPoints={true}
            selectedReferencePoint={selectedReferencPoint}
          />
        ))}

      {selectedReferenceRectangle && selectedReferenceElement && selectedReferenceRectangle.length > 0 && (
        <ReferencePoints
          color="purple"
          showReferencePoints={false}
          showMidPoints={true}
          selectedReferencePoint={null}
          onClick={(coords, event) => {
            if (event && (event.metaKey || event.ctrlKey)) {
              for (let i = 0; i < groupsKeys.length; i++) {
                const group = elementsGroups[groupsKeys[i]];
                const groupsPoints = group.map((element) => element.rectangle).flat();
                const intersectionPoints: any = [];
                for (let j = 0; j < groupsPoints.length; j++) {
                  const point = groupsPoints[j];
                  const intersection = calculateIntersectionBetweenProjectedPoints(
                    point,
                    props.selectedElements[0].angle,
                    coords,
                    props.selectedElements[0].angle - Math.PI / 2
                  );

                  if (intersection) {
                    const distanceA = distance(intersection, point);
                    intersectionPoints.push({
                      point: intersection,
                      distance: distanceA,
                      offSet: { x: intersection.x - point.x, y: intersection.y - point.y },
                    });
                  }
                }
                intersectionPoints.sort((a: any, b: any) => a.distance - b.distance);
                if (intersectionPoints.length > 0 && intersectionPoints[0]) {
                  const offSet = intersectionPoints[0].offSet;
                  onMoveElementsByOffset(group, offSet);
                }
              }
            } else if (selectedReferencPoint) {
              const difference = {
                x: coords.x - selectedReferencPoint.x,
                y: coords.y - selectedReferencPoint.y,
              };
              onMoveElementsByOffset(props.selectedElements, { x: difference.x, y: difference.y });
            }
            resetActionsByStep();
          }}
          center={selectedReferenceElement.centroid}
          userCoords={selectedReferenceRectangle}
        />
      )}

      <ReferenceForUniformAlignment
        selectedElements={props.selectedElements}
        elementsGroups={elementsGroups}
        groupsKeys={groupsKeys}
      />

      {secondaryAction.type === SecondaryActionTypes.ALIGN_SELECTED_STRUCTURES_WITH_SETBACKS &&
        linesForSetbacks &&
        linesForSetbacks.length > 0 &&
        linesForSetbacks.map((line) => (
          <FatLine
            key={line.id}
            color={line.color}
            coordinates={[
              { x: line.start.x, y: line.start.z, z: -line.start.y },
              { x: line.end.x, y: line.end.z, z: -line.end.y },
            ]}
            width={2}
            onClick={() => handleClickOnSetbackLine(line, secondaryAction.payload)}
          />
        ))}
    </>
  );
};

const SelectedElementsHOC = () => {
  const action = useModes().mode;
  if (action.type !== PrimaryActionTypes.SELECT_RECTANGLES) return null;
  return <SelectedElements selectedElements={action.payload} />;
};

export default SelectedElementsHOC;
