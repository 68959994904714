import React, { useState } from 'react';
import { useFrame } from '@react-three/fiber';
import tunnel from 'tunnel-rat';
import { useActiveArea, useOffset, useUtmZone } from '../../ilc-store';
import { useWorldCoords } from '../../hooks/use-world-coords';
import * as S from './utm-box.styled';
import { Typography } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import { Point } from '../../ilc-types';

const round = (n: number) => Math.round(n * 100) / 100;

const utmBoxTunnel = tunnel();

export function UtmBoxIn() {
  const getWorldCoords = useWorldCoords();
  const offset = useOffset();
  const utmZone = useUtmZone();
  const activeArea = useActiveArea();
  const { t } = useTranslation('ilc');
  const [vector, setVector] = useState<Point>({ x: 0, y: 0 });

  const getActiveAreaName = () => {
    if (!activeArea) return '';
    if (activeArea.areaName) return activeArea.areaName;
    return activeArea.key;
  };

  useFrame(({ pointer }) => {
    const vector = getWorldCoords(pointer);
    if (offset && utmZone) {
      const newVector = { x: round(vector.x + offset.x), y: round(vector.y + offset.y) };
      setVector((prev) => {
        if (prev.x !== newVector.x || prev.y !== newVector.y) {
          return newVector;
        }
        return prev;
      });
    }
  });

  return (
    <utmBoxTunnel.In>
      <S.UtmBoxWrapper>
        <Typography>{t('utm-box.active-area', { areaName: getActiveAreaName() })}</Typography>
        <Typography>{t('utm-box.utm-zone', { utmZone: utmZone })}</Typography>
        <Typography>{t('utm-box.x', { x: vector.x })}</Typography>
        <Typography>{t('utm-box.y', { y: vector.y })}</Typography>
      </S.UtmBoxWrapper>
    </utmBoxTunnel.In>
  );
}

export function UtmBoxOut() {
  return <utmBoxTunnel.Out />;
}
