import React, { useEffect, useState } from 'react';
import { IBessDesign, IDesign, SimulationSecondaryCharacteristicsTag } from 'types/design';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DESIGNS_STATUS } from 'utils/constants/tabs';
import { DesignItemContainer } from './design-item.styled';
import DesignItemDetails from './design-item-details';
import { CustomTag, DesignColumn } from '../../design-column.styled';
import { useConverter } from 'utils/conversor';
import { designAttributes } from 'utils/constants/design-attributes';
import { DESIGN_ACTIONS as action } from 'utils/constants/designs';
import DesignItemLoading from './loading/design-item-loading';
import classNames from 'classnames';
import { getDynamicDate } from 'utils/date';
import {
  Button,
  Checkbox,
  ConditionalWrapper,
  EditableInput,
  IOption,
  PopupWrapper,
  StatusSVGWrapper,
  Tooltip,
  Typography,
} from '@ratedpower/components';
import { hasValidCharacters, PROJECT_DESIGN_UNALLOWED_CHARS } from 'utils/validation';
import {
  ChevronDownSvg,
  ChevronUpSvg,
  CloneSvg,
  DotsSvg,
  FavouriteOutlineSvg,
  FavouriteSvg,
  FolderExportSvg,
  FolderImportSvg,
  PenSquareSvg,
  TrashSvg,
} from 'assets/icons';
import { useCanEditDesigns } from 'utils/hooks/use-can-edit-designs';
import { useCanCloneDesigns } from 'utils/hooks/use-can-clone-designs';
import { isSimulationInProgress } from 'utils/constants/status';
import { TFunction } from 'i18next';
import { SUPPORT_EMAIL } from 'utils/constants/contact';
import { buildRelativeDesignProjectUrl } from 'utils/navigation/navigation';
import { isPvDesign } from 'utils/design-type-functions';
import BessDesignItemDetails from './bess-design-item-details';
import { usePlanIsExpired } from 'utils/hooks/use-plan-is-expired';
import {
  getAccessToIlcIsDisabled,
  getAccessToIlcMessage,
  getDesignIsILC,
  getPrimaryTagText,
  getSecondaryTagText,
  selectedPrimaryTagColor,
  selectedSecondaryTagColor,
} from 'utils/design-tags';
import { useHybridBessSku } from 'utils/hooks/sku/use-hybrid-bess-sku';
import { useStandaloneBessSku } from 'utils/hooks/sku/use-standalone-bess-sku';
import { useIlcSku } from 'utils/hooks/sku/use-ilc-sku';
import { bessDesignAttributes } from 'utils/constants/bess-design-attributes';

interface IProps {
  item: IDesign | IBessDesign;
  showDetails?: boolean;
  selected: boolean;
  onEditName: (newName: string) => void;
  onPerform: (action: string) => void;
}

const NO_VALUE = '-';

const getArchiveOption = (status: string, t: TFunction) => {
  return status === DESIGNS_STATUS.archived
    ? {
        labelIcon: FolderExportSvg,
        label: t('action:unarchive'),
        value: action.unarchive,
      }
    : {
        labelIcon: FolderImportSvg,
        label: t('action:archive'),
        value: action.archive,
      };
};

const DesignItem: React.FC<IProps> = ({ item, showDetails, onEditName, selected, onPerform }) => {
  const { t } = useTranslation();
  const { restrictStandaloneBessSku } = useStandaloneBessSku();
  const { restrictHybridBessSku } = useHybridBessSku();
  const { showIlcButtonByPrimaryTag, restrictIlcSku } = useIlcSku();

  const { getValueOf } = useConverter();
  const canCloneDesigns = useCanCloneDesigns();
  const { canEditDesign } = useCanEditDesigns();
  const canEditThisDesign = canEditDesign(item.createdBy.id, item.projectTeamId);
  const [showItemDetails, setShowItemDetails] = useState(false);
  const isSimulationDesignInProgress: boolean = isSimulationInProgress(item.simulationResult);
  const designWithError: boolean = !isSimulationDesignInProgress && item.simulationResult !== 'OK';
  const tagText: string = t(getPrimaryTagText(item));

  const isRestrictedStandaloneBessDesign = restrictStandaloneBessSku(item);
  const isRestrictedHybridBessDesign = restrictHybridBessSku(item);
  const isRestrictedIlc = restrictIlcSku(item) || !canCloneDesigns;

  const showFavouriteOnly = isRestrictedStandaloneBessDesign || isRestrictedHybridBessDesign;

  const archiveOption = getArchiveOption(item.status, t);
  const deleteOption = {
    labelIcon: TrashSvg,
    label: t('action:delete'),
    value: action.delete,
  };
  const favouriteOption = item.isFavorite
    ? {
        labelIcon: FavouriteOutlineSvg,
        label: t('action:unmark-favorite'),
        value: 'favorite',
        dataTest: 'unmark-favorite-btn',
      }
    : {
        labelIcon: FavouriteSvg,
        label: t('action:mark-favorite'),
        value: 'favorite',
        dataTest: 'favorite-btn',
      };

  const options: IOption[] = showFavouriteOnly ? [favouriteOption] : [archiveOption, deleteOption, favouriteOption];
  const secondaryTagText: string = t(getSecondaryTagText(item));
  const designIsIlc = getDesignIsILC(item.secondaryTag);
  const disableAccessToIlc = getAccessToIlcIsDisabled(item) || !canCloneDesigns;

  function getKpiResults(design: IDesign | IBessDesign): {
    column1: string;
    column2: string;
    column3: string;
    column4: string;
  } {
    if (designWithError) {
      return {
        column1: NO_VALUE,
        column2: NO_VALUE,
        column3: NO_VALUE,
        column4: NO_VALUE,
      };
    } else if (isPvDesign(design)) {
      return {
        column1: getValueOf(designAttributes.power.ratedPower, design),
        column2: getValueOf(designAttributes.power.peakPower, design),
        column3: getValueOf(designAttributes.energy.specificProduction, design),
        column4: getValueOf(designAttributes.equipment.gcr, design),
      };
    } else {
      return {
        column1: getValueOf(bessDesignAttributes.bess.energyCapacity, design),
        column2: getValueOf(bessDesignAttributes.bess.activePowerAc, design),
        column3: NO_VALUE,
        column4: NO_VALUE,
      };
    }
  }

  const kpiResults = getKpiResults(item);

  const planIsExpired = usePlanIsExpired();

  useEffect(() => {
    if (showDetails !== undefined) {
      setShowItemDetails(showDetails);
    }
  }, [showDetails]);

  const handleStopPropagation = (e: any): void => {
    e.stopPropagation();
  };

  const handleToggleDetail = (e: any): void => {
    e.preventDefault();
    setShowItemDetails(!showItemDetails);
  };

  const handleClone = (e: any): void => {
    e.preventDefault();
    onPerform(action.clone);
  };

  const handleFavourite = (e: any): void => {
    e.preventDefault();
    onPerform(action.favorite);
  };

  const handleCheckBox = (): void => {
    onPerform(action.select);
  };

  const handleGetHelp = (): void => {
    window.location.href = `mailto:${SUPPORT_EMAIL}`;
  };

  const handleIlc = (e) => {
    e.preventDefault();
    onPerform(action.ilc);
  };

  if (isSimulationDesignInProgress) {
    return <DesignItemLoading item={item} onPerform={onPerform} />;
  }

  const disableClone =
    !canCloneDesigns ||
    isRestrictedStandaloneBessDesign ||
    isRestrictedHybridBessDesign ||
    designIsIlc ||
    planIsExpired;

  const isDisabledColor = isRestrictedIlc || isRestrictedStandaloneBessDesign || isRestrictedHybridBessDesign;

  const getIlcTooltip = () => {
    return t(getAccessToIlcMessage(item.layoutEditorTag));
  };

  const getCloneTooltip = () => {
    if (designIsIlc) {
      return t('design:ilc-cannot-clone');
    }
    if (disableClone) {
      return t('action:readonly');
    }
    return t('action:clone');
  };

  return (
    <Link to={buildRelativeDesignProjectUrl(item.id, item.primaryTag)}>
      <DesignItemContainer className={classNames('item', { selected })} $designWithError={designWithError}>
        <div className="main-row">
          <DesignColumn className="checkbox" onClick={handleStopPropagation}>
            <Checkbox id={item.id} checked={selected} onChange={handleCheckBox} />
          </DesignColumn>

          <DesignColumn className="favorite" onClick={handleStopPropagation}>
            <StatusSVGWrapper
              onClick={handleFavourite}
              active={item.isFavorite}
              icon={item.isFavorite ? FavouriteSvg : FavouriteOutlineSvg}
              size="m"
            />
          </DesignColumn>

          <DesignColumn className="first-column">
            {canEditThisDesign ? (
              <EditableInput
                validationFunction={hasValidCharacters(PROJECT_DESIGN_UNALLOWED_CHARS)}
                value={item.name}
                titleSize="m"
                onEditName={onEditName}
                className="editable-input"
              />
            ) : (
              <ConditionalWrapper
                condition={item.name.length > 40}
                wrapper={(children) => <Tooltip description={item.name}>{children}</Tooltip>}
              >
                <Typography as="h3" className="name" weight={'bold'} nowrap>
                  {item.name}
                </Typography>
              </ConditionalWrapper>
            )}
          </DesignColumn>
          <DesignColumn className="data tags">
            <CustomTag color={selectedPrimaryTagColor(item, isDisabledColor)}>{tagText}</CustomTag>
          </DesignColumn>
          <DesignColumn className="data tags">
            {item.secondaryTag === SimulationSecondaryCharacteristicsTag.EDITED && (
              <CustomTag color={selectedSecondaryTagColor(item, isRestrictedIlc)}>{secondaryTagText}</CustomTag>
            )}
          </DesignColumn>
          <DesignColumn className="data">
            <Typography>{kpiResults.column1}</Typography>
          </DesignColumn>
          <DesignColumn className="data">
            <Typography>{kpiResults.column2}</Typography>
          </DesignColumn>
          <DesignColumn className="data">
            <Typography>{kpiResults.column3}</Typography>
          </DesignColumn>
          <DesignColumn className="data">
            <Typography>{kpiResults.column4}</Typography>
          </DesignColumn>
          <DesignColumn className="createdAt">
            <Typography>
              {t('design:design-date', {
                dateFormat: getDynamicDate(item.publishedDate),
              })}
            </Typography>
          </DesignColumn>
          <DesignColumn className="more collapsed" onClick={handleStopPropagation}>
            {!designWithError ? (
              <StatusSVGWrapper
                icon={showItemDetails ? ChevronUpSvg : ChevronDownSvg}
                tooltip={{
                  text: t('action:show-detail'),
                  place: 'bottom',
                }}
                size={'s'}
                onClick={handleToggleDetail}
                active={false}
              />
            ) : (
              <Button onClick={handleGetHelp} text={t('design:get-help')} variant="link" textUnderlined />
            )}
            {showIlcButtonByPrimaryTag(item) && (
              <StatusSVGWrapper
                icon={PenSquareSvg}
                tooltip={{
                  text: getIlcTooltip(),
                  place: 'bottom',
                }}
                size={'s'}
                onClick={handleIlc}
                active={!canCloneDesigns || planIsExpired}
                disabled={disableAccessToIlc}
              />
            )}

            <StatusSVGWrapper
              icon={CloneSvg}
              tooltip={{
                text: getCloneTooltip(),
                place: 'bottom',
              }}
              size={'s'}
              onClick={handleClone}
              active={false}
              disabled={disableClone}
            />

            {canEditThisDesign && (
              <PopupWrapper
                popupPosition="leftCenter"
                onClickOption={(selectedOption: IOption) => onPerform(selectedOption.value)}
                options={options}
                dataTest="more-btn"
              >
                <StatusSVGWrapper
                  active={false}
                  icon={DotsSvg}
                  tooltip={{ text: t('action:more'), place: 'bottom' }}
                  size={'s'}
                />
              </PopupWrapper>
            )}
          </DesignColumn>
        </div>
        {item.status !== 'ERROR' && (
          <div
            className={classNames('detail-row', {
              hidden: !showItemDetails,
            })}
          >
            <DesignColumn className="checkbox" />
            {canEditThisDesign && <DesignColumn className="favorite" />}
            <DesignColumn className="name" />
            <DesignColumn className="details">
              {isPvDesign(item) ? <DesignItemDetails {...{ item }} /> : <BessDesignItemDetails {...{ item }} />}
            </DesignColumn>
            <DesignColumn className="createdAt" />
            {canEditThisDesign && <DesignColumn className="more" />}
          </div>
        )}
      </DesignItemContainer>
    </Link>
  );
};

export default DesignItem;
