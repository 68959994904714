import React, { useState } from 'react';
import * as S from './roles-tab.styled';
import { useUsersManagementContext } from '../users-management-data';
import PermissionList from './permission-list';
import RolesTabFiltersPanel from './roles-tab-filters-panel';
import { IServerFilter } from 'types/filter';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import { ContentTopBar } from 'components/content-top-bar';
import TopbarRoles from './topbar-roles-tab';
import { usersManagementTabs } from 'utils/constants/users';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import QueryStatusWrapper from 'components/query-status-wrapper/query-status-wrapper';
import { Modal, Banner, RPTable } from '@ratedpower/components';
import { IRoleListItem } from 'types/role';

const RolesTab: React.FC = () => {
  const { t } = useTranslation();
  const {
    useRolesObject: {
      inputSearchProps,
      loading,
      rolesError,
      table,
      currentPage,
      setFilters,
      handleCancel,
      handleEdit,
      handleSaveRole,
      handleDeleteRole,
      paginationComponent,
      deleteRoleModal,
    },
  } = useUsersManagementContext();

  const navigate = useNavigate();
  const { isAuthorized } = useAuthorization();
  const canSeePermissions = isAuthorized(Permission.PERMISSION_SUPPORT_READ);
  const [isFilterPanelOpened, setFilterPanelOpened] = useState(false);

  const navigateToTab = (tab: string) => {
    navigate(`/users-management/${tab.toLowerCase()}`);
  };

  const canCollapse = (role: IRoleListItem) => !role.editable;

  return (
    <S.RolesTabWrapper>
      <TopbarRoles activeTab={usersManagementTabs[2].id} onClickTab={navigateToTab} />
      <S.TopbarContainer>
        <Banner description={t('user:roles.add-edit-role-note')} status="informative" fitContent />
        <ContentTopBar
          searchOnChange={inputSearchProps.onChange}
          searchPlaceholder={t('action:search')}
          filterOnClick={() => setFilterPanelOpened(true)}
          marginBottom="10px"
        />
      </S.TopbarContainer>
      <S.MainView>
        <QueryStatusWrapper loading={loading} error={rolesError}>
          <RPTable
            table={table as any}
            canCollapse={canCollapse}
            pageNumber={currentPage}
            collapsibleComponent={
              canSeePermissions ? (
                <PermissionList onEdit={handleEdit} onCancel={handleCancel} onSave={handleSaveRole} />
              ) : (
                <></>
              )
            }
            zeroResultsFoundText={t('common:zero-results-found')}
          />
          {paginationComponent}
        </QueryStatusWrapper>
      </S.MainView>
      <RolesTabFiltersPanel
        active={isFilterPanelOpened}
        onHide={() => setFilterPanelOpened(false)}
        onApplyFilters={(filters: IServerFilter[]) => {
          setFilterPanelOpened(false);
          setFilters(filters);
        }}
      />
      {deleteRoleModal.isOpen && (
        <Modal
          status="error"
          isActive={deleteRoleModal.isOpen}
          title={t('user:roles.delete-role-title')}
          buttons={{
            primary: {
              text: t('action:delete'),
              onClick: () => {
                deleteRoleModal.setModalOpen(false);
                handleDeleteRole();
              },
            },
            secondary: {
              text: t('action:cancel'),
              onClick: () => deleteRoleModal.setModalOpen(false),
            },
          }}
          onClose={() => deleteRoleModal.setModalOpen(false)}
        >
          {t('user:roles.delete-role-description')}
        </Modal>
      )}
    </S.RolesTabWrapper>
  );
};

export default RolesTab;
