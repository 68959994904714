import React, { useState } from 'react';
import * as S from './users-tab.styled';
import { useTranslation } from 'react-i18next';
import { IServerFilter } from 'types/filter';
import { Modal, RatedPowerLoader, RPTable } from '@ratedpower/components';
import { ContentTopBar } from 'components/content-top-bar';
import UsersTabFiltersPanel from './users-tab-filters-panel/users-tab-filters-panel';
import UsersManagementRoleSwitch from './users-management-role-switch';
import { useUsersManagementContext } from '../users-management-data';
import TeamsSelector from 'components/teams-selector';
import DeleteConfirmationModal from 'components/modals/delete-confirmation-modal/delete-confirmation-modal';
import UserTeamsAssignedModal from '../../teams/components/user-teams-assigned-modal';
import TopbarUsersManagement from './topbar-users-tab';
import { usersManagementTabs } from 'utils/constants/users';
import { useNavigate } from 'react-router-dom';
import ZeroCreated2 from 'components/error-components/zero-created-2/zero-created-2';
import { ZeroDesignsSvg } from 'assets/icons/index';

const UsersTab: React.FC = () => {
  const {
    useUsersObject: {
      updatePasswordConfirmationModal,
      userTeamsAssignedModal,
      inputSearchProps,
      filters,
      table,
      users,
      teams,
      loading,
      setFilters,
      setTeamSelected,
      handleDeleteUser,
      handleSendPassword,
      paginationComponent,
      deleteUserModal,
      userToDelete,
    },
  } = useUsersManagementContext();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isFilterPanelOpened, setFilterPanelOpened] = useState(false);

  const navigateToTab = (tab: string) => {
    navigate(`/users-management/${tab.toLowerCase()}`);
  };

  return (
    <S.UsersTabWrapper>
      <TopbarUsersManagement activeTab={usersManagementTabs[0].id} onClickTab={navigateToTab} />
      <S.UsersTabContent>
        <UsersManagementRoleSwitch />
        <S.TopbarContainer $companyHasTeams={!!teams?.length}>
          <S.TopbarLeftContainer $companyHasTeams={!!teams?.length}>
            <TeamsSelector teams={teams} onChangeTeam={setTeamSelected} addOthersOption />
            <ContentTopBar
              searchOnChange={inputSearchProps.onChange}
              searchPlaceholder={t('user:search-bar-placeholder')}
              filterOnClick={() => setFilterPanelOpened(true)}
            />
          </S.TopbarLeftContainer>
        </S.TopbarContainer>
        <S.MainView $rowLength={table.getTotalSize()}>
          {loading ? (
            <RatedPowerLoader />
          ) : users.length > 0 ? (
            <>
              <RPTable
                table={table as any}
                selectedRows={[users?.find((user) => user.editable)?.id || '']}
                zeroResultsFoundText={t('common:zero-results-found')}
              />
              {paginationComponent}
            </>
          ) : (
            <ZeroCreated2 Icon={ZeroDesignsSvg} title={t('user:no-users')} subtitle={t('user:no-users-description')} />
          )}
        </S.MainView>
        <UsersTabFiltersPanel
          active={isFilterPanelOpened}
          onHide={() => setFilterPanelOpened(false)}
          onApplyFilters={(userFilters: IServerFilter[]) => {
            const role = filters.find((filter) => filter.field === 'ROLE');
            setFilters([...userFilters, ...(role ? [role] : [])]);
            setFilterPanelOpened(false);
          }}
          filterByStatus
        />
        <DeleteConfirmationModal
          confirmationValue={userToDelete?.email}
          confirmationValueLabel="user:delete-user-modal.confirmation-label"
          title="user:delete-user-modal.title"
          description="user:delete-user-modal.description"
          modal={deleteUserModal}
          onSubmit={handleDeleteUser}
        />
        {updatePasswordConfirmationModal.isOpen && (
          <Modal
            isActive={updatePasswordConfirmationModal.isOpen}
            title="Send new password link"
            status="info"
            buttons={{
              primary: {
                onClick: () => {
                  updatePasswordConfirmationModal.setModalOpen(false);
                  handleSendPassword(updatePasswordConfirmationModal.data);
                },
                text: t('action:send'),
              },
              secondary: {
                onClick: () => {
                  updatePasswordConfirmationModal.setModalOpen(false);
                },
                text: t('action:cancel'),
              },
            }}
            onClose={() => {
              updatePasswordConfirmationModal.setModalOpen(false);
            }}
          >
            {t('user:send-password-description')}
          </Modal>
        )}
        {userTeamsAssignedModal.isOpen && (
          <UserTeamsAssignedModal
            title={t('user:teams.teams-assigned')}
            isActive={userTeamsAssignedModal.isOpen}
            userId={userTeamsAssignedModal.data}
            onClose={() => userTeamsAssignedModal.setModalOpen(false)}
          />
        )}
      </S.UsersTabContent>
    </S.UsersTabWrapper>
  );
};

export default UsersTab;
