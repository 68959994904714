import React from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from 'access-control/permissions';
import Can from 'access-control/can';
import { ProfileHeaderContainer } from './profile.styled';
import { Typography, Button } from '@ratedpower/components';

interface IProps {
  title: string;
  onEdit: () => void;
  isEditable: boolean;
}

const ProfileHeader: React.FC<IProps> = ({ title, onEdit, isEditable }) => {
  const { t } = useTranslation();

  return (
    <ProfileHeaderContainer>
      <Typography weight="bold">{title}</Typography>
      <div className="buttons-group">
        {!isEditable && (
          <Can perform={Permission.USER_PROFILE_WRITE}>
            <Button variant="link" onClick={() => (onEdit ? onEdit() : null)} text={t('action:edit')} textUnderlined />
          </Can>
        )}
      </div>
    </ProfileHeaderContainer>
  );
};

export default ProfileHeader;
