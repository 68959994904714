import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import MaintenancePage from 'pages/maintenance/maintenance-page';
import IncidentPage from 'pages/maintenance/incident-page';
import { ScreenLoader } from '@ratedpower/components';

const useFlaggedComponent = () => {
  const { maintenanceWindow, incidenceWindow, ...flags } = useFlags();
  const flagsReady = Object.keys(flags).length > 0;

  if (!flagsReady) return <ScreenLoader />;
  if (maintenanceWindow) return <MaintenancePage />;
  if (incidenceWindow) return <IncidentPage />;

  return null;
};

export default useFlaggedComponent;
