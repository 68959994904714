import styled from 'styled-components';

export const UtmBoxWrapper = styled.div`
  padding: ${({ theme }) => theme.v2.spacing.xxs} ${({ theme }) => theme.v2.spacing.xs};
  background-color: ${({ theme }) => theme.v2.bg.primary.default};
  border-radius: ${({ theme }) => theme.v2.radius.s};
  border: 1px solid ${({ theme }) => theme.v2.stroke.primary.default};

  position: absolute;
  bottom: 24px;
  left: 16px;
  min-width: 120px;
`;
