import React, { useState } from 'react';
import * as S from './topbar-modal-add-designs.styled';
import { useTranslation } from 'react-i18next';
import { IOption } from 'types/common';
import { ISearchProps } from 'utils/hooks/use-search';
import { COMPARISON_DESIGNS_LIMIT, filterOptions, typeFilterOptions } from 'utils/constants/design-comparison';
import { Checkbox, Dropdown, Typography } from '@ratedpower/components';
import InputSearch from 'components/form-components/input-search';

interface IProps {
  inputSearchProps: ISearchProps;
  handleFolderFilter: (option: string) => void;
  handleTypeFilter: (option: string) => void;
  checkbox: {
    isOn: boolean;
    indeterminate?: boolean;
    onToggle: () => void;
  };
  designsSelected: number;
}

const TopbarModalAddDesigns: React.FC<IProps> = ({
  inputSearchProps,
  handleFolderFilter,
  handleTypeFilter,
  checkbox,
  designsSelected,
}) => {
  const { t } = useTranslation();

  const [selectedFilter, setSelectedFolder] = useState<IOption | undefined>(undefined);
  const [selectedType, setSelectedType] = useState<IOption | undefined>(undefined);

  const selectedDesignsText =
    designsSelected > 0
      ? t('design:n-designs-selected', { count: designsSelected })
      : t('design:n-designs-selected_zero');
  const warningText = t('design:you-can-only-compare-up-to-x-designs', { max: COMPARISON_DESIGNS_LIMIT });

  const handleFilterSelect = (option: IOption[]) => {
    if (option) {
      setSelectedFolder(option[0]);
      handleFolderFilter(option[0].value);
    } else {
      setSelectedFolder(undefined);
      handleFolderFilter('default');
    }
  };

  const handleTypeFilterSelect = (option: IOption[]) => {
    if (option) {
      setSelectedType(option[0]);
      handleTypeFilter(option[0].value);
    } else {
      setSelectedType(undefined);
      handleTypeFilter('default');
    }
  };

  return (
    <S.TopBarContainer>
      <S.FiltersContainer>
        <S.Selectors>
          <Checkbox
            id="select-all"
            checked={checkbox.isOn}
            indeterminate={checkbox.indeterminate}
            onChange={checkbox.onToggle}
          />
          <S.SelectedDesignsWrapper>
            <Typography as={'span'}>{selectedDesignsText}</Typography>
            <Typography as={'span'}>{warningText}</Typography>
          </S.SelectedDesignsWrapper>
        </S.Selectors>
        <S.InputsWrapper>
          <Dropdown
            key="design-type-filter"
            options={typeFilterOptions(t)}
            onChange={handleTypeFilterSelect}
            isSearchable={false}
            isClearable
            value={selectedType ? [selectedType] : []}
            placeholder={t('design:type-of-designs')}
          />
          <Dropdown
            key="folder-filter"
            options={filterOptions(t)}
            onChange={handleFilterSelect}
            isSearchable={false}
            isClearable
            value={selectedFilter ? [selectedFilter] : []}
            placeholder={t('design:folder')}
          />
          <InputSearch value={inputSearchProps.value} onChange={inputSearchProps.onChange} />
        </S.InputsWrapper>
      </S.FiltersContainer>
    </S.TopBarContainer>
  );
};

export default TopbarModalAddDesigns;
