export enum LaunchDarklyMode {
  LOCAL = 'local',
  REMOTE = 'remote',
}

export interface BootstrapConfig {
  skuIlc: boolean;
  maintenanceWindow: boolean;
  skuStandaloneBess: boolean;
  skuPrismIntegration: boolean;
  skuPrismPreview: boolean;
  skuTeamManagement: boolean;
  skuHybridBess: boolean;
  incidenceWindow: boolean;
}
  
export interface BaseConfig {
  streaming: boolean;
  sendEvents: boolean;
  bootstrap: BootstrapConfig;
}

export interface LDConfig {
  clientSideID: string;
  options: BaseConfig & {
      baseUrl?: string;
      streamUrl?: string;
      eventsUrl?: string;
  };
}