import React from 'react';
import MaintenanceWrapper from 'pages/maintenance/maintenance-wrapper/maintenance-wrapper';
import { useTranslation } from 'react-i18next';
import { IndexIds } from 'index.ids';

const IncidentPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <MaintenanceWrapper
      title={t('maintenance:sorry-technical-issue')}
      subtitle={t('maintenance:our-team-is-working')}
      dataTestId={IndexIds.Maintenance.IncidentPage}
    />
  );
};

export default IncidentPage;
