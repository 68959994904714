import React, { useEffect, useState } from 'react';
import * as S from './modal-add-designs.styled';
import { useTranslation } from 'react-i18next';
import { IDesign } from 'types/design';
import DesignCard from './design-card/design-card';
import { GET_PROJECT_DESIGNS, UPDATE_DESIGNS } from 'services/designs-gql';
import { DESIGNS_PER_PAGE as entriesPerPage } from 'utils/constants/pagination';
import { DESIGN_FOLDER_FILTERS, DESIGN_TYPE_FILTERS, DesignSortKeys } from 'utils/constants/designs';
import { getSortDirection } from 'utils/sort';
import { Button, IModalProps, RatedPowerLoader, Typography } from '@ratedpower/components';
import { useDesignComparisonData } from '../design-comparison-state';
import { COMPARISON_DESIGNS_LIMIT } from 'utils/constants/design-comparison';
import { useCustomQuery } from 'utils/hooks/use-custom-query';
import { useSearch } from 'utils/hooks/use-search';
import TopbarModalAddDesigns from './topbar-modal-add-designs/topbar-modal-add-designs';
import { useMutation } from '@apollo/client';
import ErrorRetrievingData from 'components/error-components/error-retrieving-data';

const ModalAddDesigns: React.FC<IModalProps> = (props) => {
  const { t } = useTranslation();

  const [designAvailables, setDesignAvailables] = useState<IDesign[]>([]);

  const { project, designs, addDesigns } = useDesignComparisonData();

  const [isFavorite, setIsFavorite] = useState(false);
  const [filter, setFilter] = useState(DESIGN_FOLDER_FILTERS.default);
  const [typeFilter, setTypeFilter] = useState(DESIGN_TYPE_FILTERS.default);

  const handleFolderFilter = (option: string) => {
    setIsFavorite(option === 'favorites');
    setFilter(DESIGN_FOLDER_FILTERS[option]);
  };

  const handleTypeFilter = (option: string) => {
    setTypeFilter(DESIGN_TYPE_FILTERS[option]);
  };

  const [designsSelected, setDesignsSelected] = useState<IDesign[]>(designs);

  const { getSearchFilter, inputSearchProps } = useSearch();
  const defaultModalSortSelector = {
    selector: {
      label: t('design:date'),
      code: DesignSortKeys.group1.date,
    },
    ascendent: true,
  };

  const {
    loading,
    data: fetchedData,
    error,
    paginationComponent,
    refetch,
  } = useCustomQuery(
    GET_PROJECT_DESIGNS,
    {
      variables: {
        projectId: project.id,
        pagination: {
          page: 0,
          size: entriesPerPage,
        },
        sorting: {
          property: defaultModalSortSelector.selector.code,
          direction: getSortDirection(defaultModalSortSelector.ascendent),
        },
        filters: [...filter, ...typeFilter],
        search: getSearchFilter(),
        isFavorite,
      },
      fetchPolicy: 'network-only',
    },
    true
  );

  const [updateDesigns] = useMutation(UPDATE_DESIGNS);

  const handleSwitchFavorite = async (item: IDesign) => {
    const { id, isFavorite } = item;
    const designForms = [{ id, isFavourite: !isFavorite }];

    await updateDesigns({ variables: { designForms } });
    refetch();
  };

  useEffect(() => {
    if (!fetchedData) return;
    const { result } = fetchedData;
    if (!result?.content || !result.pageInfo) return;
    const { content: fetchedDesigns } = result;

    setDesignAvailables(fetchedDesigns);
  }, [fetchedData]);

  function handleAdd() {
    addDesigns(designsSelected);
    props.onClose();
  }

  function handleSelect(design: IDesign) {
    let updatedSelected = [...designsSelected];

    if (isSelected(design)) {
      updatedSelected = updatedSelected.filter((e) => e.id !== design.id);
    } else {
      updatedSelected.push(design);
    }

    setDesignsSelected(updatedSelected);
  }

  const handleSelectAll = () => {
    if (designsSelected.length > 0) {
      setDesignsSelected([]);
    } else {
      setDesignsSelected(designAvailables);
    }
  };

  const isSelected = (currentDesign: IDesign): boolean => {
    const matches = designsSelected.find((e) => e.id === currentDesign.id);
    return !!matches;
  };

  return (
    <S.ModalAdd {...props} disableClickOutside title={t('design:project-x', { name: project.name })}>
      <S.ModalAddDesignsContainer>
        <TopbarModalAddDesigns
          designsSelected={designsSelected.length}
          handleTypeFilter={handleTypeFilter}
          handleFolderFilter={handleFolderFilter}
          checkbox={{
            isOn: designsSelected.length === designAvailables.length,
            indeterminate: designsSelected.length > 0 && designsSelected.length !== COMPARISON_DESIGNS_LIMIT,
            onToggle: handleSelectAll,
          }}
          {...{ inputSearchProps }}
        />

        <S.ModalAddDesignContent>
          {error ? (
            <ErrorRetrievingData />
          ) : (
            !loading &&
            designAvailables.length < 1 && (
              <S.ModalBodyEmpty>
                <Typography>{t('common:zero-results-found')}</Typography>
              </S.ModalBodyEmpty>
            )
          )}
          <div className="designs-grid">
            {loading && !designAvailables ? (
              <RatedPowerLoader />
            ) : (
              designAvailables.map((item) => (
                <DesignCard
                  key={item.id}
                  {...{ item }}
                  selected={isSelected(item)}
                  onFavorite={handleSwitchFavorite}
                  onSelect={() => handleSelect(item)}
                />
              ))
            )}
            {paginationComponent}
          </div>
        </S.ModalAddDesignContent>
        <S.ModalBottomBar>
          <S.ButtonsWrapper>
            <Button dataTest="cancel-button" onClick={props.onClose} text={t('action:cancel')} variant={'ghost'} />
            <Button
              disabled={!designsSelected.length || designsSelected.length > COMPARISON_DESIGNS_LIMIT || error}
              onClick={handleAdd}
              text={t('action:add')}
              variant={'accent'}
            />
          </S.ButtonsWrapper>
        </S.ModalBottomBar>
      </S.ModalAddDesignsContainer>
    </S.ModalAdd>
  );
};

export default ModalAddDesigns;
