import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Typography } from '@ratedpower/components';
import { Blocker } from 'react-router-dom';
import { track } from 'rudderstack/utils';
import { RudderstackEvent } from 'rudderstack/types';

interface IUnsavedLayoutChangesModal {
  blocker: Blocker;
}

const UnsavedLayoutChangesModal = ({ blocker }: IUnsavedLayoutChangesModal) => {
  const { t } = useTranslation(['ilc', 'common', 'action']);

  const handleLeaveAnyway = () => {
    if (blocker.state === 'blocked') {
      track(RudderstackEvent.PV_EDITED_BACK_TO_PROJECT);
      blocker.proceed();
    }
  };

  const handleClose = () => {
    if (blocker.state === 'blocked') {
      blocker.reset();
    }
  };

  return (
    <Modal
      status="warn"
      title={t('common:unsaved-changes-modal.title')}
      isActive
      id={'unsaved-changes-modal'}
      onClose={handleClose}
      buttons={{
        primary: {
          text: t('action:no-return'),
          onClick: handleClose,
        },
        secondary: {
          text: t('action:yes-leave-anyway'),
          onClick: handleLeaveAnyway,
        },
      }}
    >
      <Typography>{t('ilc:modals.unsaved-changes-modal-content')}</Typography>
    </Modal>
  );
};

export { UnsavedLayoutChangesModal };
