import { BaseConfig, LaunchDarklyMode, LDConfig } from '../types/launch-darkly';

export const getCurrentMode = (): LaunchDarklyMode => 
    Object.values(LaunchDarklyMode).includes(process.env.REACT_APP_LAUNCH_DARKLY_MODE as LaunchDarklyMode) 
        ? process.env.REACT_APP_LAUNCH_DARKLY_MODE as LaunchDarklyMode 
        : LaunchDarklyMode.REMOTE;

const baseConfig: BaseConfig = {
    streaming: true,
    sendEvents: true,
    bootstrap: {
      skuIlc: false,
      maintenanceWindow: false,
      skuStandaloneBess: false,
      skuPrismIntegration: false,
      skuPrismPreview: false,
      skuTeamManagement: false,
      skuHybridBess: false,
      incidenceWindow: false,
    },
  };
  
export const getLDConfig = (): LDConfig => {
    if (getCurrentMode() === LaunchDarklyMode.LOCAL) {
      return {
        clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_PROJECT_KEY ?? '',
        options: {
          ...baseConfig,
          baseUrl: 'http://localhost:8765/',
          streamUrl: 'http://localhost:8765/',
          eventsUrl: 'http://localhost:8765/',
        },
      };
    }
  
    return {
      clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID ?? '',
      options: baseConfig,
    };
  };